import React from 'react';

import FileCopyIcon from '@mui/icons-material/FileCopy';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { types } from 'sunwise-template-core';
import { Box, Typography } from 'sunwise-ui';

import { useBreakpoint } from 'common/hooks';
import { formatDate } from 'common/utils/dates';

const CustomTemplateColumns = ({
    isForSummaryTemplates,
    languagesDictionary,
}) => {
    const { t } = useTranslation();
    const breakpoint = useBreakpoint();
    const getType = (type) => {
        const typesMap = {
            [types.ONE_PROPOSAL_TYPE]: t('Proposal'),
            [types.MULTIPROPOSAL_TYPE]: t('Summary'),
            [types.SMART_DOCUMENTS_TYPE]: t('Smart document'),
            [types.REPORT_TYPE]: t('Report'),
        };
        return typesMap?.[type] || t('Not available');
    };

    const nameColumn = [
        {
            align: 'left',
            display: 'flex',
            field: 'title',
            flex: 1,
            headerName: t('Title'),
            renderCell: (params) => {
                const { row } = params;
                return (
                    <Box display="flex" flexDirection="column">
                        <Typography variant="caption">{row.title}</Typography>
                        <Typography variant="caption">
                            {row.total_pages}{' '}
                            {t('Sheet', { count: row.total_pages })}
                        </Typography>
                    </Box>
                );
            },
        },
    ];

    let columns = [
        ...nameColumn,
        {
            align: 'left',
            display: 'flex',
            field: 'proposals_number',
            flex: 1,
            headerName: t('Proposal', { count: 2 }),
            hide: !isForSummaryTemplates,
            renderCell: (params) => {
                const { row } = params;
                return (
                    <Box alignItems="center" display="flex" gap={1}>
                        <FileCopyIcon fontSize="small" />
                        <Typography variant="body2">
                            {row?.proposals_number}{' '}
                            {t('Proposal', {
                                count: row?.proposals_number,
                            })}
                        </Typography>
                    </Box>
                );
            },
        },
        {
            align: 'left',
            display: 'flex',
            field: 'language',
            flex: 1,
            headerName: t('Language'),
            renderCell: (params) => {
                const { row } = params;
                if (languagesDictionary[row?.language])
                    return languagesDictionary[row?.language]?.language;
                return null;
            },
        },
        {
            align: 'left',
            display: 'flex',
            field: 'type',
            flex: 1,
            headerName: t('Type'),
            renderCell: (params) => {
                const { row } = params;
                return getType(row?.type);
            },
        },
        {
            align: 'left',
            display: 'flex',
            field: 'created_at',
            flex: 1,
            headerName: t('Creation date'),
            renderCell: (params) => {
                const { row } = params;
                const date = new Date(row?.created_at);
                return formatDate(date, 'yyyy-MM-dd');
            },
        },
    ];

    if (['xs', 'sm'].includes(breakpoint)) columns = nameColumn;

    return columns;
};

CustomTemplateColumns.propTypes = {
    isForSummaryTemplates: PropTypes.bool,
    languagesDictionary: PropTypes.object,
};

export default CustomTemplateColumns;
