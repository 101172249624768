import React from 'react';

import { get, orderBy } from 'lodash';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Typography } from 'sunwise-ui';

import { useBreakpoint } from 'common/hooks';
import BranchOfficeFormatter from 'common/modules/multiBranches/components/BranchOfficeFormatter';
import { getSessionLocalStorage } from 'common/utils/helpers/session';

import ActionsFormatter from './ActionsFormatter';
import InstallationTypeFormatter from './InstallationTypeFormatter';

const ProductColumns = ({
    canDelete,
    canModify,
    filterData,
    handleClickBulkItems,
    handleClickDelete,
    handleClickUpdate,
    hiddenColumns,
    orders,
    prepareDuplicate,
    rowModesModel,
    selectedRowId,
    setRowModesModel,
    setSelectedRowId,
}) => {
    const { t } = useTranslation();
    const breakpoint = useBreakpoint();
    const session = getSessionLocalStorage();
    const companyGroup = get(session, 'company_group', null);

    const nameColumn = [
        {
            align: 'left',
            display: 'flex',
            editable: canModify,
            field: 'name',
            flex: 1,
            headerName: t('Plan'),
            hide: hiddenColumns.includes('name'),
            order: orders?.name,
            renderCell: (params) => {
                const { row } = params;
                return (
                    <Typography
                        fontWeight="bold"
                        onClick={() => handleClickUpdate(row?.id, true)}
                        sx={{ cursor: 'pointer' }}
                        variant="caption"
                    >
                        {row.name}
                    </Typography>
                );
            },
        },
    ];

    let columns = [
        ...nameColumn,
        {
            align: 'left',
            display: 'flex',
            field: 'installation_type',
            flex: 1,
            minWidth: 180,
            headerName: t('Rate'),
            hide: hiddenColumns.includes('installation_type'),
            order: orders?.installation_type,
            renderCell: (params) => {
                const { row } = params;
                return <InstallationTypeFormatter row={row} />;
            },
        },
        {
            align: 'left',
            display: 'flex',
            field: 'branch_office',
            flex: 1,
            minWidth: 180,
            headerName: t('Branch office'),
            hide:
                get(companyGroup, 'type', 2) > 0 ||
                hiddenColumns.includes('branch_office'),
            order: orders?.branch_office,
            renderCell: (params) => {
                const { row } = params;
                return BranchOfficeFormatter(row.branch_office, row);
            },
        },
    ];

    if (['xs', 'sm'].includes(breakpoint)) {
        columns = nameColumn;
    }

    columns = [
        ...columns,
        {
            align: 'left',
            field: '',
            flex: 1,
            renderCell: (params) => {
                const { row } = params;
                return (
                    <ActionsFormatter
                        canDelete={canDelete}
                        canModify={canModify}
                        filterData={filterData}
                        handleClickBulkItems={handleClickBulkItems}
                        handleClickDelete={handleClickDelete}
                        handleClickUpdate={handleClickUpdate}
                        prepareDuplicate={prepareDuplicate}
                        row={row}
                        rowModesModel={rowModesModel}
                        selectedRowId={selectedRowId}
                        setRowModesModel={setRowModesModel}
                        setSelectedRowId={setSelectedRowId}
                    />
                );
            },
            sortable: false,
        },
    ];

    return orderBy(columns, ['order'], ['asc']);
};

ProductColumns.propTypes = {
    canDelete: PropTypes.bool,
    canModify: PropTypes.bool,
    filterData: PropTypes.object,
    handleClickBulkItems: PropTypes.func,
    handleClickDelete: PropTypes.func,
    handleClickUpdate: PropTypes.func,
    hiddenColumns: PropTypes.array,
    orders: PropTypes.object,
    prepareDuplicate: PropTypes.func,
    rowModesModel: PropTypes.object,
    selectedRowId: PropTypes.func,
    setRowModesModel: PropTypes.func,
    setSelectedRowId: PropTypes.func,
};

export default ProductColumns;
