import React, { useEffect } from 'react';

import { styled } from '@mui/material/styles';
import { Box, Chip, DataGrid } from '@sunwisesoftware/sunwise-ui';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import { CustomPagination } from 'common/components';
import { TABLE_PAGE_SIZE_OPTIONS } from 'common/constants';
import { useBreakpoint } from 'common/hooks';
import { scroll } from 'common/utils/mixins';

import { DEFAULT_ALL_STATUS_KEY, REPORT_TYPES_CONFIG } from '../constants';

import ElectricBills from './ElectricBills';
import Periods from './Periods';
import ReportName from './ReportName';

const getRowStyles = (selectable) => {
    const styles = { '& .MuiDataGrid-cell:focus': { outline: 'none' } };
    if (selectable) styles['& .MuiDataGrid-row'] = { cursor: 'pointer' };
    return styles;
};

const StyledDataGrid = styled(DataGrid)`
    & .MuiDataGrid-virtualScroller {
        ${scroll.custom()}
    }
`;

const getCorrectPageSize = (pageSize) =>
    TABLE_PAGE_SIZE_OPTIONS.includes(pageSize)
        ? pageSize
        : TABLE_PAGE_SIZE_OPTIONS[0];

const ReportTable = ({
    data,
    fetchReports,
    filterQuery,
    localeDateFormat,
    onSelectReport,
}) => {
    const { t } = useTranslation();

    const breakpoint = useBreakpoint();

    const {
        data: columnData,
        loading,
        meta: { page, page_size: pageSize, total } = {},
    } = data?.[DEFAULT_ALL_STATUS_KEY] || {};

    useEffect(() => {
        handleFetchReports({ filterQuery });
    }, [filterQuery]);

    const handleFetchReports = ({ filterQuery, newPage, newPageSize } = {}) => {
        if (loading) return;

        fetchReports({
            filterQuery,
            page: newPage || 1,
            pageSize: getCorrectPageSize(newPageSize || pageSize),
            replaceValues: true,
        });
    };

    const columns = [
        {
            align: 'left',
            display: 'flex',
            field: 'name',
            flex: 1,
            headerName: t('Name'),
            minWidth: 200,
            renderCell: ({ row }) => (
                <ReportName
                    name={row?.name}
                    resolution={row?.resolution}
                    showResolution
                    status={row?.complements?.status}
                />
            ),
            sortable: false,
        },
        {
            align: 'left',
            display: 'flex',
            field: 'period',
            flex: 1,
            headerName: t('Period', { count: 2 }),
            minWidth: 230,
            sortable: false,
            renderCell: ({ row }) => (
                <Box>
                    <Periods
                        data={row.report_periods?.[0]?.group_periods}
                        localeDateFormat={localeDateFormat}
                    />
                </Box>
            ),
        },
    ];

    if (!['xs', 'sm'].includes(breakpoint))
        columns.push(
            {
                align: 'left',
                display: 'flex',
                field: 'type',
                flex: 1,
                headerName: t('Type'),
                minWidth: 190,
                renderCell: ({ row }) => {
                    const config =
                        REPORT_TYPES_CONFIG[
                            row.report_periods?.[0]?.report_type
                        ];

                    if (!config?.label) return null;

                    return <Chip label={t(config.label)} />;
                },
                sortable: false,
            },
            {
                align: 'left',
                display: 'flex',
                field: 'electric_bill',
                flex: 1,
                headerName: t('Electric bill'),
                minWidth: 200,
                renderCell: ({ row }) => (
                    <Box>
                        <ElectricBills
                            localeDateFormat={localeDateFormat}
                            periods={row.report_periods?.[0]?.group_periods}
                        />
                    </Box>
                ),
                sortable: false,
            },
        );

    columns.push({
        align: 'left',
        display: 'flex',
        field: 'project',
        flex: 1,
        headerName: t('Project'),
        minWidth: 200,
        sortable: false,
        valueGetter: (_, row) => row?.project?.name || '--',
    });

    const getRowHeight = () => 'auto';
    const initialState = {
        pagination: {
            paginationModel: { pageSize: TABLE_PAGE_SIZE_OPTIONS[0] },
        },
    };
    const paginationModel = {
        page: page - 1 || 0,
        pageSize,
    };
    const onPaginationModelChange = ({ page, pageSize }) =>
        handleFetchReports({
            filterQuery,
            newPageSize: pageSize,
            newPage: page + 1,
        });

    const slotProps = {
        pagination: { pageSizeOptions: TABLE_PAGE_SIZE_OPTIONS },
    };
    const slots = { pagination: CustomPagination };
    const rowCount = total || 0;
    const rows = columnData || [];
    const onRowClick = (params) => onSelectReport(params.row);

    return (
        <StyledDataGrid
            autoHeight
            columns={columns}
            disableColumnMenu
            disableRowSelectionOnClick
            getRowHeight={getRowHeight}
            hideFooterSelectedRowCount
            initialState={initialState}
            loading={loading}
            onPaginationModelChange={onPaginationModelChange}
            onRowClick={onRowClick}
            pagination
            paginationMode="server"
            paginationModel={paginationModel}
            rowCount={rowCount}
            rows={rows}
            slotProps={slotProps}
            slots={slots}
            sx={getRowStyles(true)}
        />
    );
};

ReportTable.propTypes = {
    data: PropTypes.object,
    fetchReports: PropTypes.func,
    filterQuery: PropTypes.string,
    localeDateFormat: PropTypes.string,
    onSelectReport: PropTypes.func,
};

export default ReportTable;
