import React from 'react';

import DeleteIcon from '@mui/icons-material/Delete';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import DropdownCardMenu from 'common/components/cards/DropdownCardMenu';

import * as actions from '../actions';
import { SOURCE_FIELD_KEYS } from '../constants';
import * as selectors from '../selectors';

import InfoCell from './InfoCell';
import Table from './Table';

const ManualSourceList = ({
    canDelete,
    disabled,
    externalSources,
    fetchExternalSources,
    isFetchingExternalSources,
    prepareDeleteExternalSource,
    selectedProjectId,
}) => {
    const { t } = useTranslation();
    const infoKeys = Object.values(SOURCE_FIELD_KEYS);

    const handleFetchExternalSources = () =>
        fetchExternalSources(selectedProjectId);

    const columns = [
        {
            align: 'left',
            display: 'flex',
            field: 'brand',
            flex: 1,
            headerName: t('Brand'),
            minWidth: 200,
            sortable: false,
            valueGetter: (_, row) => row?.brand || '--',
        },
        {
            align: 'left',
            display: 'flex',
            field: 'name',
            flex: 1,
            headerName: t('Name'),
            minWidth: 200,
            sortable: false,
            valueGetter: (_, row) => row?.name || '--',
        },
        {
            align: 'left',
            display: 'flex',
            field: 'type',
            flex: 1,
            headerName: t('Type'),
            minWidth: 200,
            sortable: false,
            valueGetter: (_, row) => row?.type || '--',
        },
        {
            align: 'left',
            display: 'flex',
            field: 'information',
            flex: 2,
            headerName: t('Information'),
            minWidth: 200,
            renderCell: ({ row }) => <InfoCell infoKeys={infoKeys} row={row} />,
            sortable: false,
        },
        {
            align: 'right',
            display: 'flex',
            field: 'actions',
            headerName: '',
            renderCell: ({ row }) => (
                <DropdownCardMenu
                    items={[
                        {
                            disabled,
                            label: t('View'),
                            icon: <OpenInNewIcon />,
                            link: row?.url,
                            linkOptions: {
                                component: 'a',
                                rel: 'noopener noreferrer',
                                target: '_blank',
                            },
                            visible: Boolean(row?.url),
                        },
                        {
                            disabled,
                            label: t('Delete'),
                            icon: <DeleteIcon />,
                            handleClick: () =>
                                prepareDeleteExternalSource(
                                    row?.id,
                                    handleFetchExternalSources,
                                ),
                            visible: canDelete,
                        },
                    ]}
                    visible={canDelete}
                />
            ),
            sortable: false,
            width: 50,
        },
    ];

    return (
        <Table
            columns={columns}
            isLoading={isFetchingExternalSources}
            items={externalSources}
            selectable={false}
        />
    );
};

ManualSourceList.propTypes = {
    canDelete: PropTypes.bool,
    disabled: PropTypes.bool,
    externalSources: PropTypes.array,
    fetchExternalSources: PropTypes.func,
    isFetchingExternalSources: PropTypes.bool,
    prepareDeleteExternalSource: PropTypes.func,
    selectedProjectId: PropTypes.string,
};

const mapStateToProps = createStructuredSelector({
    isFetchingExternalSources: selectors.getIsFetchingExternalSources,
});

const mapDispatchToProps = (dispatch) => ({
    fetchExternalSources: (id) => dispatch(actions.fetchExternalSources(id)),
    prepareDeleteExternalSource: (id, successCallback) =>
        dispatch(actions.prepareDeleteExternalSource(id, successCallback)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ManualSourceList);
