import React, { useEffect, useRef, useState } from 'react';

import debounce from 'lodash/debounce';
import isEmpty from 'lodash/isEmpty';
import isEqual from 'lodash/isEqual';
import PropTypes from 'prop-types';
import { Card, DataGrid } from 'sunwise-ui';

import CustomPagination from 'common/components/CustomPagination';
import { DEBOUNCE_TIME_1500, TABLE_PAGE_SIZE_OPTIONS } from 'common/constants';

import Columns from './Columns';

const ListItems = ({
    canDelete,
    canModify,
    filterData,
    filterItems,
    handleClickDelete,
    handleClickUpdate,
    isFetching,
    items,
    paginationData,
    setFilterData,
}) => {
    const [rowCount, setRowCount] = useState(paginationData?.rowCount || 0);
    const [currentFilterData, setCurrentFilterData] = useState(filterData);

    useEffect(() => {
        if (isEmpty(paginationData)) return;
        setRowCount((prev) =>
            paginationData?.total_rows !== undefined
                ? paginationData?.total_rows
                : prev,
        );
    }, [paginationData?.total_rows, setRowCount]);

    useEffect(() => {
        if (!isEqual(currentFilterData, filterData)) {
            handleOnFilter(filterData);
            setCurrentFilterData(filterData);
        }
    }, [filterData]);

    const handleOnFilter = useRef(
        debounce((filter) => filterItems(filter), DEBOUNCE_TIME_1500),
    ).current;

    const columns = Columns({
        canDelete,
        canModify,
        handleClickDelete,
        handleClickUpdate,
    });
    const getRowHeight = () => 'auto';
    const initialState = {
        pagination: {
            paginationModel: { pageSize: TABLE_PAGE_SIZE_OPTIONS[0] },
        },
    };
    const onPaginationModelChange = (model) =>
        setFilterData((prev) => ({ ...prev, ...model }));
    const pageSizeOptions = TABLE_PAGE_SIZE_OPTIONS;
    const paginationModel = {
        page: paginationData?.current_page - 1 || 0,
        pageSize: filterData?.pageSize || TABLE_PAGE_SIZE_OPTIONS[0],
    };
    const slotProps = { pagination: { pageSizeOptions } };
    const slots = { pagination: CustomPagination };

    return (
        <Card>
            <Card.Body>
                <DataGrid
                    autoHeight
                    columns={columns}
                    disableColumnMenu
                    disableRowSelectionOnClick
                    getRowHeight={getRowHeight}
                    initialState={initialState}
                    loading={isFetching}
                    onPaginationModelChange={onPaginationModelChange}
                    pageSizeOptions={pageSizeOptions}
                    pagination
                    paginationMode="server"
                    paginationModel={paginationModel}
                    rowCount={rowCount}
                    rows={items}
                    slotProps={slotProps}
                    slots={slots}
                    sortingMode="server"
                />
            </Card.Body>
        </Card>
    );
};

ListItems.propTypes = {
    canDelete: PropTypes.bool,
    canModify: PropTypes.bool,
    filterData: PropTypes.object,
    filterItems: PropTypes.func,
    handleClickDelete: PropTypes.func,
    handleClickUpdate: PropTypes.func,
    isFetching: PropTypes.bool,
    items: PropTypes.array,
    paginationData: PropTypes.object,
    setFilterData: PropTypes.func,
};

export default ListItems;
