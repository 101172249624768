import React, { useEffect, useRef, useState } from 'react';

import debounce from 'lodash/debounce';
import isEmpty from 'lodash/isEmpty';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { DataGrid, Typography } from 'sunwise-ui';

import { SinglePagination } from 'common/components';
import { DEBOUNCE_TIME_1500, TABLE_PAGE_SIZE_OPTIONS } from 'common/constants';
import { getCurrencyIso, numberFormat } from 'common/utils/helpers';
import { getCountryCurrencyLocale } from 'common/utils/helpers/session';

import ProposalsNameFormatter from './ProposalsNameFormatter';

const ProposalsTable = ({
    filterItems,
    isFetching,
    items,
    paginationData,
    projectId,
}) => {
    const { t } = useTranslation();
    const [rowCount, setRowCount] = useState(paginationData?.rowCount || 0);
    const [filterData, setFilterData] = useState({
        orderBy: 'created_at',
        page: 0,
        pageSize: 5,
        projectId,
        sortBy: 'desc',
    });

    const handleOnFilter = useRef(
        debounce((filter) => filterItems(filter), DEBOUNCE_TIME_1500),
    ).current;

    useEffect(() => {
        handleOnFilter(filterData);
    }, [filterData]);

    useEffect(() => {
        if (isEmpty(paginationData)) return;
        setRowCount((prev) =>
            paginationData?.total_rows !== undefined
                ? paginationData?.total_rows
                : prev,
        );
    }, [paginationData?.total_rows, setRowCount]);

    const columnName = {
        display: 'flex',
        field: 'name',
        flex: 1,
        headerName: t('Name'),
        renderCell: ProposalsNameFormatter,
    };

    const columns = [
        columnName,
        {
            display: 'flex',
            field: 'total',
            headerName: t('Total'),
            minWidth: 150,
            renderCell: (params) => {
                const { row } = params;
                const currencyIso = getCurrencyIso(row?.currency);
                const currencyLocale = getCountryCurrencyLocale();
                return (
                    <Typography variant="body2">
                        {numberFormat(row.total, {
                            currency: currencyIso,
                            locale: currencyLocale,
                            style: 'currency',
                        })}
                    </Typography>
                );
            },
        },
    ];
    const getRowHeight = () => 'auto';
    const initialState = {
        pagination: {
            paginationModel: { pageSize: TABLE_PAGE_SIZE_OPTIONS[0] },
        },
    };
    const onPaginationModelChange = (model) =>
        setFilterData((prev) => ({ ...prev, ...model }));
    const onSortModelChange = (model) => {
        if (!model[0]) return;
        setFilterData((prev) => ({
            ...prev,
            orderBy: model[0].field.toLocaleLowerCase(),
            sortBy: model[0].sort,
        }));
    };
    const pageSizeOptions = TABLE_PAGE_SIZE_OPTIONS;
    const paginationModel = {
        page: paginationData?.current_page - 1 || 0,
        pageSize: filterData?.pageSize || TABLE_PAGE_SIZE_OPTIONS[0],
    };
    const slotProps = { pagination: { pageSizeOptions } };
    const slots = { pagination: SinglePagination };

    return (
        <DataGrid
            autoHeight
            columns={columns}
            disableColumnMenu
            disableRowSelectionOnClick
            getRowHeight={getRowHeight}
            initialState={initialState}
            loading={isFetching}
            onPaginationModelChange={onPaginationModelChange}
            onSortModelChange={onSortModelChange}
            pageSizeOptions={pageSizeOptions}
            pagination
            paginationMode="server"
            paginationModel={paginationModel}
            rowCount={rowCount}
            rows={items}
            slotProps={slotProps}
            slots={slots}
            sortingMode="server"
        />
    );
};

ProposalsTable.propTypes = {
    filterItems: PropTypes.func,
    isFetching: PropTypes.bool,
    items: PropTypes.array,
    paginationData: PropTypes.object,
    projectId: PropTypes.string,
};

export default ProposalsTable;
