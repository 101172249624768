import React from 'react';

import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Typography } from 'sunwise-ui';

import { useBreakpoint } from 'common/hooks';

import ContactFormatter from './ContactFormatter';
import DateFormatter from './DateFormatter';
import DescriptionFormatter from './DescriptionFormatter';
import UserFormatter from './UserFormatter';

const CompactColumns = ({ canModify, users }) => {
    const { t } = useTranslation();
    const breakpoint = useBreakpoint();
    const columnName = {
        display: 'flex',
        field: 'contact',
        headerName: t('Contact'),
        minWidth: 250,
        renderCell: (params) => {
            const { row } = params;
            return <ContactFormatter canModify={canModify} row={row} />;
        },
    };

    let columns = [
        columnName,
        {
            display: 'flex',
            field: 'description',
            headerName: t('Follow-up'),
            minWidth: 350,
            renderCell: (params) => {
                const { row } = params;
                return (
                    <DescriptionFormatter
                        description={row?.description}
                        users={users}
                    />
                );
            },
        },
        {
            display: 'flex',
            field: 'objective',
            headerName: t('Objective'),
            minWidth: 120,
            renderCell: (params) => {
                const { row } = params;
                return (
                    <Typography variant="caption">
                        {row?.tracking_purpose?.name}
                    </Typography>
                );
            },
        },
        {
            display: 'flex',
            field: 'user',
            headerName: t('User'),
            minWidth: 250,
            renderCell: UserFormatter,
        },
        {
            display: 'flex',
            field: 'notification_at',
            minWidth: 200,
            headerName: t('Date'),
            renderCell: DateFormatter,
        },
    ];

    if (breakpoint === 'xs')
        columns = [
            columnName,
            {
                display: 'flex',
                field: 'notification_at',
                headerName: t('Date'),
                minWidth: 200,
                renderCell: DateFormatter,
            },
        ];

    return columns;
};

CompactColumns.propTypes = {
    canModify: PropTypes.bool,
    users: PropTypes.array,
};

export default CompactColumns;
