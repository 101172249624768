import React, { useEffect, useRef, useState } from 'react';

import { debounce, isEqual } from 'lodash';
import PropTypes from 'prop-types';
import { DataGrid } from 'sunwise-ui';

import {
    AddProductColumns,
    CustomPagination,
    FiltersProductsCard,
    YouNoNotSeeTheProduct,
} from 'common/components';
import { DEBOUNCE_TIME_1500, TABLE_PAGE_SIZE_OPTIONS } from 'common/constants';
import ProductBranchOfficesModal from 'common/modules/multiBranches/components/ProductBranchOfficesModal';

const ListItemsPanels = ({
    addProductToBranches,
    branchOfficesDictionary,
    branchSelected,
    canModify,
    data,
    filterItemsParent,
    isFetching,
    pagination,
    panelBrands,
}) => {
    const [productSelected, selectProduct] = useState(null);
    const [
        productBranchOfficesModalPosition,
        setProductBranchOfficesModalPosition,
    ] = useState({ x: 0, y: 0 });
    const [rowCount, setRowCount] = useState(pagination?.rowCount || 0);
    const [filterData, setFilterData] = useState({
        brandName: '',
        maxPower: '',
        orderBy: 'created_at',
        page: 0,
        pageSize: 25,
        searchText: '',
        sortBy: 'desc',
    });
    const [currentFilterData, setCurrentFilterData] = useState(filterData);

    useEffect(() => {
        setRowCount((prevRowCountState) =>
            pagination?.total_rows !== undefined
                ? pagination?.total_rows
                : prevRowCountState,
        );
    }, [pagination?.total_rows, setRowCount]);

    useEffect(() => {
        if (!isEqual(currentFilterData, filterData)) {
            handleOnFilter(filterData);
            setCurrentFilterData(filterData);
        }
    }, [filterData]);

    const handleOnFilter = useRef(
        debounce((filter) => filterItemsParent(filter), DEBOUNCE_TIME_1500),
    ).current;

    const handleAddProductToBranches = (id, branches, successCallback) =>
        addProductToBranches(id, branches, () => {
            filterItemsParent(filterData);
            successCallback && successCallback();
        });

    const handleOnChangeMaxPower = (event) =>
        setFilterData({
            ...filterData,
            page: 0,
            maxPower: event?.target?.value,
        });

    const handleOnChangeSearch = (event) =>
        setFilterData({
            ...filterData,
            page: 0,
            searchText: event?.target?.value,
        });

    const handleOnSelectBrand = (value) => {
        const filter = {
            ...filterData,
            brandName: value,
            page: 0,
        };
        setFilterData(filter);
    };

    const columns = AddProductColumns({
        branchOfficesDictionary,
        branchSelected,
        canModify,
        handleAddProductToBranches,
        productKey: 'panels',
        productSelected,
        selectProduct,
        setProductBranchOfficesModalPosition,
    });
    const getRowHeight = () => 'auto';
    const initialState = {
        pagination: {
            paginationModel: { pageSize: TABLE_PAGE_SIZE_OPTIONS[0] },
        },
    };
    const onPaginationModelChange = (model) =>
        setFilterData({ ...filterData, ...model });
    const onSortModelChange = (sortModel) => {
        if (!sortModel[0]) return;
        setFilterData({
            ...filterData,
            orderBy: sortModel[0].field.toLocaleLowerCase(),
            sortBy: sortModel[0].sort,
        });
    };
    const pageSizeOptions = TABLE_PAGE_SIZE_OPTIONS;
    const paginationModel = {
        page: pagination?.current_page - 1 || 0,
        pageSize: pagination?.per_page || TABLE_PAGE_SIZE_OPTIONS[0],
    };
    const slotProps = { pagination: { pageSizeOptions } };
    const slots = { pagination: CustomPagination };

    return (
        <>
            <FiltersProductsCard
                filterData={filterData}
                handleOnChangeMaxPower={handleOnChangeMaxPower}
                handleOnChangeSearch={handleOnChangeSearch}
                handleOnSelectBrand={handleOnSelectBrand}
                maxPowerLabel="W"
                productBrands={panelBrands}
            />

            <DataGrid
                autoHeight
                columns={columns}
                disableColumnMenu
                getRowHeight={getRowHeight}
                initialState={initialState}
                loading={isFetching}
                onPaginationModelChange={onPaginationModelChange}
                onSortModelChange={onSortModelChange}
                pageSizeOptions={pageSizeOptions}
                pagination
                paginationMode="server"
                paginationModel={paginationModel}
                rowCount={rowCount}
                rows={data}
                slotProps={slotProps}
                slots={slots}
                sortingMode="server"
                sx={{ my: 2 }}
            />

            <YouNoNotSeeTheProduct />

            <ProductBranchOfficesModal
                branchOfficesDictionary={branchOfficesDictionary}
                canModify={canModify}
                handleClickClose={() => selectProduct(null)}
                isOpen={productSelected !== null}
                position={productBranchOfficesModalPosition}
                productSelected={productSelected}
                submitChanges={(branches, successCallback) =>
                    handleAddProductToBranches(
                        productSelected.id,
                        branches,
                        successCallback,
                    )
                }
            />
        </>
    );
};

ListItemsPanels.propTypes = {
    addProductToBranches: PropTypes.func,
    branchOfficesDictionary: PropTypes.object,
    branchSelected: PropTypes.string,
    canModify: PropTypes.bool,
    data: PropTypes.array,
    isFetching: PropTypes.bool,
    pagination: PropTypes.object,
    filterItemsParent: PropTypes.func,
    panelBrands: PropTypes.array,
};

export default ListItemsPanels;
