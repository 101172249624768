import React, { useEffect, useRef, useState } from 'react';

import debounce from 'lodash/debounce';
import isEmpty from 'lodash/isEmpty';
import PropTypes from 'prop-types';
import { Card, DataGrid } from 'sunwise-ui';

import CustomPagination from 'common/components/CustomPagination';
import { DEBOUNCE_TIME_1500, TABLE_PAGE_SIZE_OPTIONS } from 'common/constants';
import CustomColumns from 'common/modules/customColumns';
import { getOrderedColumns, getHiddenColumns } from 'common/utils/helpers';

import { COLUMNS_CONFIG } from '../constants';

import BulkOperationsCard from './BulkOperationsCard';
import Columns from './Columns';

const ListItems = ({
    filterData,
    filterItems,
    handleClickBulkItems,
    handleClickView,
    isFetching,
    items,
    paginationData,
    rates,
    setFilterData,
    tableSettings,
    users,
}) => {
    const [rowCount, setRowCount] = useState(paginationData?.rowCount || 0);
    const [rowModesModel, setRowModesModel] = useState({});
    const [selectionModel, setSelectionModel] = useState([]);

    useEffect(() => {
        if (isEmpty(paginationData)) return;
        setRowCount((prev) =>
            paginationData?.total_rows !== undefined
                ? paginationData?.total_rows
                : prev,
        );
    }, [paginationData?.total_rows, setRowCount]);

    useEffect(() => {
        handleOnFilter(filterData);
    }, [filterData]);

    const handleOnFilter = useRef(
        debounce((filter) => filterItems(filter), DEBOUNCE_TIME_1500),
    ).current;

    const handleChange = (key, value, resetPage = false) => {
        setFilterData((prev) => ({
            ...prev,
            [key]: value,
            ...(resetPage ? { page: 0 } : {}),
        }));
    };

    const initialDates = {
        end_date: filterData.endDate,
        start_date: filterData.startDate,
    };

    const columns = Columns({
        handleClickView,
        hiddenColumns: getHiddenColumns(tableSettings),
        orders: getOrderedColumns(tableSettings),
    });
    const columnVisibilityModel = columns.reduce(
        (acc, column) => ({ ...acc, [column.field]: !column.hide }),
        {},
    );
    const getRowHeight = () => 'auto';
    const initialState = {
        pagination: {
            paginationModel: { pageSize: TABLE_PAGE_SIZE_OPTIONS[0] },
        },
    };
    const onPaginationModelChange = (model) =>
        setFilterData((prev) => ({ ...prev, ...model }));
    const onRowModesModelChange = (model) => setRowModesModel(model);
    const onRowSelectionModelChange = (model) => setSelectionModel(model);
    const onSortModelChange = (model) => {
        if (!model[0]) return;
        setFilterData((prev) => ({
            ...prev,
            orderBy: model[0].field.toLocaleLowerCase(),
            sortBy: model[0].sort,
        }));
    };
    const pageSizeOptions = TABLE_PAGE_SIZE_OPTIONS;
    const paginationModel = {
        page: paginationData?.current_page - 1 || 0,
        pageSize: filterData?.pageSize || TABLE_PAGE_SIZE_OPTIONS[0],
    };
    const slotProps = { pagination: { pageSizeOptions } };
    const slots = { pagination: CustomPagination };

    return (
        <>
            <BulkOperationsCard
                filterData={filterData}
                handleChange={handleChange}
                handleClickBulkItems={handleClickBulkItems}
                initialDates={initialDates}
                rates={rates}
                selectionModel={selectionModel}
                setSelectionModel={setSelectionModel}
                users={users}
            />

            <Card>
                <Card.Body>
                    <CustomColumns.Container
                        columnsConfig={COLUMNS_CONFIG}
                        tableKey="proposals"
                    />

                    <DataGrid
                        autoHeight
                        columnVisibilityModel={columnVisibilityModel}
                        columns={columns}
                        disableColumnMenu
                        disableRowSelectionOnClick
                        getRowHeight={getRowHeight}
                        initialState={initialState}
                        loading={isFetching}
                        onPaginationModelChange={onPaginationModelChange}
                        onRowModesModelChange={onRowModesModelChange}
                        onRowSelectionModelChange={onRowSelectionModelChange}
                        onSortModelChange={onSortModelChange}
                        pageSizeOptions={pageSizeOptions}
                        pagination
                        paginationMode="server"
                        paginationModel={paginationModel}
                        rowCount={rowCount}
                        rowModesModel={rowModesModel}
                        rows={items}
                        selectionModel={selectionModel}
                        slotProps={slotProps}
                        slots={slots}
                        sortingMode="server"
                    />
                </Card.Body>
            </Card>
        </>
    );
};

ListItems.propTypes = {
    filterData: PropTypes.object,
    filterItems: PropTypes.func,
    handleClickBulkItems: PropTypes.func,
    handleClickView: PropTypes.func,
    isFetching: PropTypes.bool,
    items: PropTypes.array,
    paginationData: PropTypes.object,
    rates: PropTypes.array,
    setFilterData: PropTypes.func,
    tableSettings: PropTypes.array,
    users: PropTypes.array,
};

export default ListItems;
