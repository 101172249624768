import React from 'react';

import orderBy from 'lodash/orderBy';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Typography } from 'sunwise-ui';

import { useBreakpoint } from 'common/hooks';

import ActionsFormatter from './ActionsFormatter';

const ProductColumns = ({
    canModify,
    handleChangeSwitch,
    handleClickProduct,
    hiddenColumns,
    orders,
    rowModesModel,
    selectedRowId,
    setRowModesModel,
    setSelectedRowId,
}) => {
    const { t } = useTranslation();
    const breakpoint = useBreakpoint();

    let columns = [
        {
            align: 'left',
            display: 'flex',
            field: 'name',
            flex: 1,
            headerName: t('Name'),
            hide: hiddenColumns.includes('name'),
            order: orders?.name,
            renderCell: (params) => {
                const { row } = params;
                return (
                    <Typography
                        variant="caption"
                        onClick={() => handleClickProduct(row)}
                        sx={{ cursor: 'pointer' }}
                    >
                        {row.name}
                    </Typography>
                );
            },
        },
        {
            align: 'left',
            display: 'flex',
            field: 'financier',
            flex: 1,
            headerName: t('Ally'),
            hide: hiddenColumns.includes('financier'),
            order: orders?.financier,
            valueGetter: (_, row) => row?.financier?.name,
        },
        {
            align: 'left',
            display: 'flex',
            field: 'type',
            flex: 1,
            headerName: t('Type'),
            hide: hiddenColumns.includes('type'),
            order: orders?.type,
            renderCell: ({ row }) =>
                row?.type === 'special' ? t('Special') : t('Public'),
        },
        {
            align: 'left',
            display: 'flex',
            field: 'created_at',
            flex: 1,
            headerName: t('Creation date'),
            hide: hiddenColumns.includes('created_at'),
            order: orders?.created_at,
            renderCell: (params) => {
                const { row } = params;
                return row?.created_at;
            },
        },
    ];

    if (['xs', 'sm'].includes(breakpoint)) {
        columns = [
            {
                align: 'left',
                display: 'flex',
                field: 'name',
                flex: 1,
                headerName: t('Name'),
                renderCell: (params) => {
                    const { row } = params;
                    return (
                        <Typography
                            variant="caption"
                            onClick={() => handleClickProduct(row)}
                            sx={{ cursor: 'pointer' }}
                        >
                            {row.name}
                        </Typography>
                    );
                },
            },
        ];
    }

    columns = [
        ...columns,
        {
            align: 'left',
            display: 'flex',
            field: '',
            flex: 1,
            renderCell: (params) => {
                const { row } = params;
                return (
                    <ActionsFormatter
                        canModify={canModify}
                        handleChangeSwitch={handleChangeSwitch}
                        row={row}
                        rowModesModel={rowModesModel}
                        selectedRowId={selectedRowId}
                        setRowModesModel={setRowModesModel}
                        setSelectedRowId={setSelectedRowId}
                    />
                );
            },
            sortable: false,
        },
    ];

    return orderBy(columns, ['order'], ['asc']);
};

ProductColumns.propTypes = {
    canDelete: PropTypes.bool,
    canModify: PropTypes.bool,
    filterData: PropTypes.object,
    handleChangeSwitch: PropTypes.func,
    handleClickDelete: PropTypes.func,
    handleClickProduct: PropTypes.func,
    handleClickUpdate: PropTypes.func,
    hiddenColumns: PropTypes.array,
    orders: PropTypes.object,
    rowModesModel: PropTypes.object,
    selectedRowId: PropTypes.func,
    setRowModesModel: PropTypes.func,
    setSelectedRowId: PropTypes.func,
};

export default ProductColumns;
