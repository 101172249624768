import React from 'react';

import { useTranslation } from 'react-i18next';

import { useBreakpoint } from 'common/hooks';

import ContactFormatter from './ContactFormatter';
import DateFormatter from './DateFormatter';
import ProjectFormatter from './ProjectFormatter';
import ProposalFormatter from './ProposalFormatter';
import RateFormatter from './RateFormatter';
import SalesmanFormatter from './SalesmanFormatter';

const ProposalColumns = () => {
    const { t } = useTranslation();
    const breakpoint = useBreakpoint();

    let columns = [
        {
            display: 'flex',
            field: 'parent',
            headerName: t('Name'),
            minWidth: 250,
            renderCell: (params) => {
                const { row } = params;
                return <ProposalFormatter row={row} />;
            },
        },
        {
            display: 'flex',
            field: 'project',
            headerName: t('Project'),
            minWidth: 250,
            renderCell: ProjectFormatter,
        },
        {
            display: 'flex',
            field: 'contact',
            headerName: t('Contact'),
            minWidth: 250,
            renderCell: (params) => {
                const { row } = params;
                return <ContactFormatter row={row} />;
            },
        },
        {
            display: 'flex',
            field: 'created_by',
            headerName: t('User'),
            minWidth: 180,
            renderCell: SalesmanFormatter,
        },
        {
            display: 'flex',
            field: 'rate',
            headerName: t('Rate'),
            minWidth: 120,
            renderCell: RateFormatter,
        },
        {
            display: 'flex',
            field: 'created_at',
            headerName: t('Date'),
            minWidth: 120,
            renderCell: DateFormatter,
        },
    ];

    if (breakpoint === 'xs') {
        columns = [
            {
                display: 'flex',
                field: 'parent',
                headerName: t('Name'),
                minWidth: 250,
                renderCell: ProposalFormatter,
            },
            {
                display: 'flex',
                field: 'project',
                headerName: t('Project'),
                minWidth: 250,
                renderCell: ProjectFormatter,
            },
        ];
    }
    return columns;
};

ProposalColumns.propTypes = {};

export default ProposalColumns;
