import React, { useEffect, useState, useRef } from 'react';

import debounce from 'lodash/debounce';
import isEmpty from 'lodash/isEmpty';
import isEqual from 'lodash/isEqual';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { types } from 'sunwise-template-core';
import { Card, DataGrid } from 'sunwise-ui';

import { CustomPagination } from 'common/components';
import BulkOperationsCard from 'common/components/BulkOperationsCard';
import { DEBOUNCE_TIME_1500, TABLE_PAGE_SIZE_OPTIONS } from 'common/constants';
import CustomColumns from 'common/modules/customColumns';
import { getOrderedColumns, getHiddenColumns } from 'common/utils/helpers';

import { COLUMNS_CONFIG } from '../constants';

import ProductColumns from './ProductColumns';

const ListItems = ({
    branchOfficesDictionary,
    branchSelected,
    canDelete,
    canModify,
    companyUsersData,
    filterData,
    filterItems,
    handleClickBulkItems,
    handleClickDelete,
    handleClickDuplicate,
    handleClickUpdate,
    handleSaveRow,
    isFetching,
    isForSummaryTemplates,
    items,
    languagesDictionary,
    paginationData,
    setFilterData,
    tableSettings,
    templateType,
}) => {
    const { t } = useTranslation();
    const [rowCount, setRowCount] = useState(paginationData?.rowCount || 0);
    const [rowModesModel, setRowModesModel] = useState({});
    const [selectedRowId, setSelectedRowId] = useState(null);
    const [selectionModel, setSelectionModel] = useState([]);
    const [currentFilterData, setCurrentFilterData] = useState(filterData);

    useEffect(() => {
        if (isEmpty(paginationData)) return;
        setRowCount((prev) =>
            paginationData?.total_rows !== undefined
                ? paginationData?.total_rows
                : prev,
        );
    }, [paginationData?.total_rows, setRowCount]);

    useEffect(() => {
        if (isEqual(currentFilterData, filterData)) return;
        handleOnFilter(filterData);
        setCurrentFilterData(filterData);
    }, [filterData]);

    const handleOnFilter = useRef(
        debounce((filter) => filterItems(filter), DEBOUNCE_TIME_1500),
    ).current;

    const handleOnChangeSearch = (event) =>
        setFilterData((prev) => ({
            ...prev,
            page: 0,
            searchText: event.target.value,
        }));

    const handleOnSelectStatus = (status) =>
        setFilterData((prev) => ({ ...prev, page: 0, status }));

    const getColumnKeys = () =>
        templateType === types.MULTIPROPOSAL_TYPE
            ? { ...COLUMNS_CONFIG, proposals_number: 'Proposal_plural' }
            : COLUMNS_CONFIG;

    const getTableKey = () => {
        switch (templateType) {
            case types.MULTIPROPOSAL_TYPE:
                return 'summaries';
            case types.SMART_DOCUMENTS_TYPE:
                return 'smart_documents';
            case types.REPORT_TYPE:
                return 'reports';
            default:
                return 'proposals';
        }
    };

    const checkboxSelection = canDelete;
    const columns = ProductColumns({
        branchOfficesDictionary,
        branchSelected,
        canDelete,
        canModify,
        companyUsersData,
        filterData,
        handleClickBulkItems,
        handleClickDelete,
        handleClickDuplicate,
        handleClickUpdate,
        hiddenColumns: getHiddenColumns(tableSettings),
        isForSummaryTemplates,
        languagesDictionary,
        orders: getOrderedColumns(tableSettings),
        rowModesModel,
        selectedRowId,
        setRowModesModel,
        setSelectedRowId,
    });
    const columnVisibilityModel = columns.reduce(
        (acc, column) => ({ ...acc, [column.field]: !column.hide }),
        {},
    );
    const getRowHeight = () => 'auto';
    const initialState = {
        pagination: {
            paginationModel: { pageSize: TABLE_PAGE_SIZE_OPTIONS[0] },
        },
    };
    const onPaginationModelChange = (model) =>
        setFilterData((prev) => ({ ...prev, ...model }));
    const onRowModesModelChange = (model) => setRowModesModel(model);
    const onRowSelectionModelChange = (model) => setSelectionModel(model);
    const onSortModelChange = (sortModel) => {
        if (!sortModel[0]) return;
        setFilterData((prev) => ({
            ...prev,
            orderBy: sortModel[0].field.toLocaleLowerCase(),
            sortBy: sortModel[0].sort,
        }));
    };
    const pageSizeOptions = TABLE_PAGE_SIZE_OPTIONS;
    const paginationModel = {
        page: paginationData?.current_page - 1 || 0,
        pageSize: filterData?.pageSize || TABLE_PAGE_SIZE_OPTIONS[0],
    };
    const processRowUpdate = (newRow) => {
        handleSaveRow(newRow);
        return newRow;
    };
    const slotProps = { pagination: { pageSizeOptions } };
    const slots = { pagination: CustomPagination };

    return (
        <>
            <BulkOperationsCard
                canDelete={canDelete}
                filterData={filterData}
                handleClickBulkItems={handleClickBulkItems}
                handleOnChangeSearch={handleOnChangeSearch}
                handleOnSelectStatus={handleOnSelectStatus}
                prefix={t('Template', {
                    count: selectionModel.length,
                }).toLocaleLowerCase()}
                selectionModel={selectionModel}
                setSelectionModel={setSelectionModel}
            />

            <Card>
                <Card.Body>
                    <CustomColumns.Container
                        columnsConfig={getColumnKeys()}
                        tableKey={getTableKey()}
                    />

                    <DataGrid
                        autoHeight
                        checkboxSelection={checkboxSelection}
                        columnVisibilityModel={columnVisibilityModel}
                        columns={columns}
                        disableColumnMenu
                        disableRowSelectionOnClick
                        editMode="row"
                        getRowHeight={getRowHeight}
                        initialState={initialState}
                        loading={isFetching}
                        onPaginationModelChange={onPaginationModelChange}
                        onRowModesModelChange={onRowModesModelChange}
                        onRowSelectionModelChange={onRowSelectionModelChange}
                        onSortModelChange={onSortModelChange}
                        pageSizeOptions={pageSizeOptions}
                        pagination
                        paginationMode="server"
                        paginationModel={paginationModel}
                        processRowUpdate={processRowUpdate}
                        rowCount={rowCount}
                        rowModesModel={rowModesModel}
                        rows={items}
                        selectionModel={selectionModel}
                        slotProps={slotProps}
                        slots={slots}
                        sortingMode="server"
                    />
                </Card.Body>
            </Card>
        </>
    );
};

ListItems.propTypes = {
    branchOfficesDictionary: PropTypes.array,
    branchSelected: PropTypes.string,
    canDelete: PropTypes.bool,
    canModify: PropTypes.bool,
    companyUsersData: PropTypes.array,
    filterData: PropTypes.object,
    filterItems: PropTypes.func,
    handleClickBulkItems: PropTypes.func,
    handleClickDelete: PropTypes.func,
    handleClickDuplicate: PropTypes.func,
    handleClickUpdate: PropTypes.func,
    handleSaveRow: PropTypes.func,
    isFetching: PropTypes.bool,
    isForSummaryTemplates: PropTypes.bool,
    items: PropTypes.array,
    languagesDictionary: PropTypes.object,
    paginationData: PropTypes.object,
    setFilterData: PropTypes.func,
    tableSettings: PropTypes.array,
    templateType: PropTypes.number,
};

export default ListItems;
