import React, { useEffect, useState, useRef } from 'react';

import { debounce, isEqual, isNull } from 'lodash';
import PropTypes from 'prop-types';
import { Card, DataGrid } from 'sunwise-ui';

import { CustomPagination } from 'common/components';
import { DEBOUNCE_TIME_1500, TABLE_PAGE_SIZE_OPTIONS } from 'common/constants';
import CustomColumns from 'common/modules/customColumns';
import { getOrderedColumns, getHiddenColumns } from 'common/utils/helpers';

import { COLUMNS_CONFIG } from '../constants';

import BulkOperationsCard from './BulkOperationsCard';
import Columns from './Columns';

const ListItems = ({
    agents,
    canDelete,
    canModify,
    contactTypes,
    downloadFile,
    filterData,
    filterItems,
    handleClickBulkItems,
    handleSaveRow,
    isFetching,
    items,
    paginationData,
    setFilterData,
    tableSettings,
    uploadFile,
}) => {
    const [rowCount, setRowCount] = useState(paginationData?.rowCount || 0);
    const [rowModesModel, setRowModesModel] = useState({});
    const [selectedRowId, setSelectedRowId] = useState(null);
    const [selectionModel, setSelectionModel] = useState([]);
    const [currentFilterData, setCurrentFilterData] = useState(filterData);

    useEffect(() => {
        if (paginationData)
            setRowCount((prevRowCount) =>
                paginationData?.total_rows !== undefined
                    ? paginationData?.total_rows
                    : prevRowCount,
            );
    }, [paginationData?.total_rows]);

    useEffect(() => {
        if (!isEqual(currentFilterData, filterData)) {
            handleOnFilter(filterData);
            setCurrentFilterData(filterData);
        }
    }, [filterData]);

    const handleOnFilter = useRef(
        debounce((filter) => filterItems(filter), DEBOUNCE_TIME_1500),
    ).current;

    const handleOnChangeSearch = (event) => {
        setFilterData({
            ...filterData,
            page: 0,
            searchText: event.target.value,
        });
    };

    const handleOnSelectAgent = (item) =>
        setFilterData({
            ...filterData,
            agentId: item.value,
            page: 0,
        });

    const handleOnSelectContactType = (item) =>
        setFilterData({
            ...filterData,
            contactType: item.value,
            page: 0,
        });

    const handleOnSelectStatus = (status) =>
        setFilterData({ ...filterData, page: 0, status });

    const handleUpdateEmails = (row) => {
        const { email, emails } = row;
        if (emails?.length === 0) return [{ email_type: '0', email }];
        return emails.map((item, index) =>
            index === 0 ? { ...item, email } : item,
        );
    };

    const checkboxSelection = canDelete || canModify ? true : false;
    const columns = Columns({
        canDelete,
        canModify,
        filterData,
        handleClickBulkItems,
        hiddenColumns: getHiddenColumns(tableSettings),
        orders: getOrderedColumns(tableSettings),
        rowModesModel,
        selectedRowId,
        setRowModesModel,
        setSelectedRowId,
    });
    const columnVisibilityModel = columns.reduce(
        (acc, column) => ({ ...acc, [column.field]: !column.hide }),
        {},
    );
    const getRowHeight = () => 'auto';
    const initialState = {
        pagination: {
            paginationModel: { pageSize: TABLE_PAGE_SIZE_OPTIONS[0] },
        },
    };
    const onPaginationModelChange = (model) =>
        setFilterData({ ...filterData, ...model });
    const onRowEditStart = (params, event) => {
        const id = params.row.id;
        if (isNull(selectedRowId)) setSelectedRowId(id);
        else event.defaultMuiPrevented = true;
        return false;
    };
    const onRowEditStop = (_, event) => {
        event.defaultMuiPrevented = true;
    };
    const onRowModesModelChange = (model) => setRowModesModel(model);
    const onRowSelectionModelChange = (model) => setSelectionModel(model);
    const onSortModelChange = (sortModel) => {
        if (!sortModel[0]) return;
        setFilterData({
            ...filterData,
            orderBy: sortModel[0].field.toLocaleLowerCase(),
            sortBy: sortModel[0].sort,
        });
    };
    const paginationModel = {
        page: paginationData?.current_page - 1 || 0,
        pageSize: filterData?.pageSize || TABLE_PAGE_SIZE_OPTIONS[0],
    };
    const processRowUpdate = (newRow, oldRow) => {
        const newEmails = handleUpdateEmails(newRow);
        handleSaveRow({
            ...newRow,
            emails: newEmails,
            previusEmail: oldRow.email,
        });
        return oldRow;
    };
    const pageSizeOptions = TABLE_PAGE_SIZE_OPTIONS;
    const slotProps = { pagination: { pageSizeOptions } };
    const slots = { pagination: CustomPagination };

    return (
        <>
            <BulkOperationsCard
                agents={agents}
                canDelete={canDelete}
                canModify={canModify}
                contactTypes={contactTypes}
                downloadFile={downloadFile}
                filterData={filterData}
                handleClickBulkItems={handleClickBulkItems}
                handleOnChangeSearch={handleOnChangeSearch}
                handleOnSelectAgent={handleOnSelectAgent}
                handleOnSelectContactType={handleOnSelectContactType}
                handleOnSelectStatus={handleOnSelectStatus}
                selectionModel={selectionModel}
                setSelectionModel={setSelectionModel}
                uploadFile={uploadFile}
            />

            <Card>
                <Card.Body>
                    <CustomColumns.Container
                        columnsConfig={COLUMNS_CONFIG}
                        tableKey="contacts"
                    />

                    <DataGrid
                        autoHeight
                        checkboxSelection={checkboxSelection}
                        columnVisibilityModel={columnVisibilityModel}
                        columns={columns}
                        disableColumnMenu
                        disableRowSelectionOnClick
                        editMode="row"
                        getRowHeight={getRowHeight}
                        initialState={initialState}
                        loading={isFetching}
                        onPaginationModelChange={onPaginationModelChange}
                        onRowEditStart={onRowEditStart}
                        onRowEditStop={onRowEditStop}
                        onRowModesModelChange={onRowModesModelChange}
                        onRowSelectionModelChange={onRowSelectionModelChange}
                        onSortModelChange={onSortModelChange}
                        pageSizeOptions={pageSizeOptions}
                        pagination
                        paginationMode="server"
                        paginationModel={paginationModel}
                        processRowUpdate={processRowUpdate}
                        rowCount={rowCount}
                        rowModesModel={rowModesModel}
                        rows={items}
                        selectionModel={selectionModel}
                        slotProps={slotProps}
                        slots={slots}
                        sortingMode="server"
                    />
                </Card.Body>
            </Card>
        </>
    );
};

ListItems.propTypes = {
    agents: PropTypes.array,
    canDelete: PropTypes.bool,
    canModify: PropTypes.bool,
    contactTypes: PropTypes.array,
    downloadFile: PropTypes.func,
    filterData: PropTypes.object,
    filterItems: PropTypes.func,
    handleClickBulkItems: PropTypes.func,
    handleSaveRow: PropTypes.func,
    isFetching: PropTypes.bool,
    items: PropTypes.array,
    paginationData: PropTypes.object,
    setFilterData: PropTypes.func,
    tableSettings: PropTypes.array,
    uploadFile: PropTypes.func,
};

export default ListItems;
