import React from 'react';

import PropTypes from 'prop-types';
import { DataGrid } from 'sunwise-ui';

import { CustomPagination } from 'common/components';
import { TABLE_PAGE_SIZE_OPTIONS } from 'common/constants';

import ProductColumns from './ProductColumns';

const ListItems = ({ isFetching, isFetchingRoles, items }) => {
    const columns = ProductColumns();
    const getRowHeight = () => 'auto';
    const initialState = {
        pagination: {
            paginationModel: { pageSize: TABLE_PAGE_SIZE_OPTIONS[0] },
        },
    };
    const loading = isFetching || isFetchingRoles;
    const pageSizeOptions = TABLE_PAGE_SIZE_OPTIONS;
    const slotProps = { pagination: { pageSizeOptions } };
    const slots = { pagination: CustomPagination };

    return (
        <DataGrid
            autoHeight
            columns={columns}
            disableColumnMenu
            disableRowSelectionOnClick
            getRowHeight={getRowHeight}
            initialState={initialState}
            loading={loading}
            pagination
            rows={items}
            slotProps={slotProps}
            slots={slots}
        />
    );
};

ListItems.propTypes = {
    isFetching: PropTypes.bool,
    isFetchingRoles: PropTypes.bool,
    items: PropTypes.array,
};

export default ListItems;
