import React from 'react';

import orderBy from 'lodash/orderBy';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Typography } from 'sunwise-ui';

import { useBreakpoint } from 'common/hooks';

import ActionsFormatter from './ActionsFormatter';

const ProductColumns = ({
    canDelete,
    canModify,
    filterData,
    handleClickBulkItems,
    handleClickUpdate,
    hiddenColumns,
    orders,
    rowModesModel,
    selectedRowId,
    setRowModesModel,
    setSelectedRowId,
}) => {
    const { t } = useTranslation();
    const breakpoint = useBreakpoint();

    const nameColumn = [
        {
            align: 'left',
            display: 'flex',
            editable: canModify,
            field: 'name',
            flex: 1,
            headerName: t('Name'),
            hide: hiddenColumns.includes('name'),
            order: orders?.name,
            renderCell: (params) => {
                const { row } = params;
                return (
                    <Typography
                        fontWeight="bold"
                        onClick={() => handleClickUpdate(row?.id, true)}
                        sx={{ cursor: 'pointer' }}
                        variant="caption"
                    >
                        {row.name}
                    </Typography>
                );
            },
        },
    ];

    let columns = [
        ...nameColumn,
        {
            align: 'left',
            display: 'flex',
            field: 'template',
            flex: 1,
            headerName: t('Template'),
            hide: hiddenColumns.includes('template'),
            order: orders?.template,
            renderCell: (params) => {
                const { row } = params;
                return (
                    <Typography variant="caption">
                        {row?.template?.title || '---'}
                    </Typography>
                );
            },
        },
    ];

    if (['xs', 'sm'].includes(breakpoint)) {
        columns = nameColumn;
    }

    columns = [
        ...columns,
        {
            align: 'left',
            display: 'flex',
            field: '',
            flex: 1,
            renderCell: (params) => {
                const { row } = params;
                return (
                    <ActionsFormatter
                        canDelete={canDelete}
                        canModify={canModify}
                        filterData={filterData}
                        handleClickBulkItems={handleClickBulkItems}
                        handleClickUpdate={handleClickUpdate}
                        row={row}
                        rowModesModel={rowModesModel}
                        selectedRowId={selectedRowId}
                        setRowModesModel={setRowModesModel}
                        setSelectedRowId={setSelectedRowId}
                    />
                );
            },
            sortable: false,
        },
    ];

    return orderBy(columns, ['order'], ['asc']);
};

ProductColumns.propTypes = {
    canDelete: PropTypes.bool,
    canModify: PropTypes.bool,
    handleClickBulkItems: PropTypes.func,
    handleClickUpdate: PropTypes.func,
    hiddenColumns: PropTypes.array,
    orders: PropTypes.object,
    rowModesModel: PropTypes.object,
    selectedRowId: PropTypes.func,
    setRowModesModel: PropTypes.func,
    setSelectedRowId: PropTypes.func,
};

export default ProductColumns;
