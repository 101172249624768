import React, { useEffect, useRef, useState } from 'react';

import { debounce } from 'lodash';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Checkbox, DataGrid } from 'sunwise-ui';

import {
    CustomPagination,
    InputSearch,
    ProductNameFormatter,
} from 'common/components';
import { DEBOUNCE_TIME_500, TABLE_PAGE_SIZE_OPTIONS } from 'common/constants';
import { useBreakpoint } from 'common/hooks';

const ModalCurrencyList = ({
    canDelete,
    data,
    filterItemsParent,
    handleSelectParent,
    handleUnselectParent,
    isFetching,
    pagination,
}) => {
    console.log({ pagination });
    const { t } = useTranslation();
    const [rowCount, setRowCount] = useState(pagination?.rowCount || 0);
    const [filterData, setFilterData] = useState({
        orderBy: 'name',
        page: 0,
        pageSize: TABLE_PAGE_SIZE_OPTIONS[0],
        searchText: '',
        sortBy: 'asc',
    });
    const [searchText, setSearchText] = useState('');
    const breakpoint = useBreakpoint();

    useEffect(() => {
        setRowCount((prevRowCountState) =>
            pagination?.total_rows !== undefined
                ? pagination?.total_rows
                : prevRowCountState,
        );
    }, [pagination?.total_rows, setRowCount]);

    useEffect(() => {
        console.log({ filterData });
        filterItemsParent(filterData);
    }, [filterData]);

    const FormatterActions = (_, row) => (
        <Checkbox
            checked={row.added}
            disabled={!row.can_remove || !canDelete || row.added}
            onChange={(e) => {
                if (e.target.checked) handleSelectParent(row, e.target);
                else handleUnselectParent(row.id);
            }}
        />
    );

    const onChangeSearch = (searchText) =>
        setFilterData((prev) => ({ ...prev, page: 0, searchText }));

    const handleOnChangeValue = useRef(
        debounce(onChangeSearch, DEBOUNCE_TIME_500),
    ).current;

    const handleOnChangeSearch = (event) => {
        setSearchText(event.target.value);
        handleOnChangeValue(event.target.value);
    };

    let columns = [
        {
            display: 'flex',
            field: 'name',
            flex: 1,
            headerName: t('Name'),
            renderCell: (params) => {
                const { row } = params;
                return ProductNameFormatter(row.name, row);
            },
        },
        {
            display: 'flex',
            field: 'symbol',
            flex: 1,
            headerName: t('Symbol'),
        },
        {
            display: 'flex',
            field: 'abbreviation',
            flex: 1,
            headerName: t('ISO code'),
        },
    ];

    if (breakpoint === 'xs') {
        columns = [
            {
                display: 'flex',
                field: 'name',
                flex: 1,
                headerName: t('Name'),
                renderCell: (params) => {
                    const { row } = params;
                    return ProductNameFormatter(row.name, row);
                },
            },
        ];
    }

    columns = [
        ...columns,
        {
            display: 'flex',
            field: '',
            flex: 1,
            headerName: t('Add'),
            renderCell: (params) => {
                const { row } = params;
                return FormatterActions(null, row);
            },
            sortable: false,
        },
    ];
    const getRowHeight = () => 'auto';
    const initialState = {
        pagination: {
            paginationModel: { pageSize: TABLE_PAGE_SIZE_OPTIONS[0] },
        },
    };
    const onPaginationModelChange = (model) =>
        setFilterData((prev) => ({ ...prev, ...model }));
    const onSortModelChange = (model) => {
        if (!model[0]) return;
        setFilterData((prev) => ({
            ...prev,
            orderBy: model[0].field.toLocaleLowerCase(),
            sortBy: model[0].sort,
        }));
    };
    const paginationModel = {
        page: pagination?.current_page - 1 || 0,
        pageSize: pagination?.per_page || TABLE_PAGE_SIZE_OPTIONS[0],
    };
    const pageSizeOptions = TABLE_PAGE_SIZE_OPTIONS;
    const slotProps = { pagination: { pageSizeOptions } };
    const slots = { pagination: CustomPagination };

    return (
        <>
            <InputSearch
                onChange={handleOnChangeSearch}
                placeholder={t('Filter by name, brand or model')}
                value={searchText}
            />

            <DataGrid
                autoHeight
                columns={columns}
                disableColumnMenu
                disableRowSelectionOnClick
                getRowHeight={getRowHeight}
                initialState={initialState}
                loading={isFetching}
                onPaginationModelChange={onPaginationModelChange}
                onSortModelChange={onSortModelChange}
                pagination
                paginationMode="server"
                paginationModel={paginationModel}
                rowCount={rowCount}
                rows={data}
                slotProps={slotProps}
                slots={slots}
                sortingMode="server"
                sx={{ my: 2 }}
            />
        </>
    );
};

ModalCurrencyList.propTypes = {
    canDelete: PropTypes.bool,
    data: PropTypes.array,
    filterItemsParent: PropTypes.func,
    handleSelectParent: PropTypes.func,
    handleUnselectParent: PropTypes.func,
    isFetching: PropTypes.bool,
    pagination: PropTypes.object,
};

export default ModalCurrencyList;
