import React from 'react';

import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { DataGrid } from 'sunwise-ui';

import { CustomPagination } from 'common/components';
import { DAYS_NAMES } from 'common/constants';
import { getDayLabel, numberFormat } from 'common/utils/helpers';
import { getCountryCurrencyLocale } from 'common/utils/helpers/session';

import { compareRowsByColumn, getEnergyWarningsRows } from '../helpers';

const WarningsList = ({
    consumptionHistory,
    emptyEnergyIndexes,
    handleGoToDay,
    realIndex,
}) => {
    const { t } = useTranslation();
    if (!emptyEnergyIndexes?.length) return null;

    const countryCurrencyLocale = getCountryCurrencyLocale();
    const rows = getEnergyWarningsRows({ emptyEnergyIndexes, realIndex });
    const translatedDays = DAYS_NAMES.map((day) => getDayLabel(day));

    let columns = [
        {
            display: 'flex',
            field: 'year',
            headerName: t('Year'),
            minWidth: 50,
            flex: 1,
            sortComparator: (v1, v2, cellParams1, cellParams2) => {
                if (v1 === v2) return 0;
                return compareRowsByColumn({
                    columnId: 'year',
                    id1: cellParams1?.rowNode?.id,
                    id2: cellParams2?.rowNode?.id,
                    rows,
                });
            },
            valueGetter: (_, row) => row.year + 1,
        },
        {
            display: 'flex',
            field: 'period',
            headerName: t('Period'),
            minWidth: 100,
            flex: 1,
            sortComparator: (v1, v2, cellParams1, cellParams2) => {
                if (v1 === v2) return 0;
                return compareRowsByColumn({
                    columnId: 'period',
                    id1: cellParams1?.rowNode?.id,
                    id2: cellParams2?.rowNode?.id,
                    rows,
                });
            },
            valueGetter: (_, row) =>
                consumptionHistory?.[row.year]?.[row.period]?.label,
        },
        {
            display: 'flex',
            field: 'week',
            headerName: t('Week'),
            minWidth: 100,
            flex: 1,
            sortComparator: (v1, v2, cellParams1, cellParams2) => {
                if (v1 === v2) return 0;
                return compareRowsByColumn({
                    columnId: 'week',
                    id1: cellParams1?.rowNode?.id,
                    id2: cellParams2?.rowNode?.id,
                    rows,
                });
            },
        },
        {
            display: 'flex',
            field: 'day',
            headerName: t('Day'),
            minWidth: 100,
            flex: 1,
            valueGetter: (_, row) => translatedDays[row.day || 0],
            sortComparator: (v1, v2, cellParams1, cellParams2) => {
                if (v1 === v2) return 0;
                return compareRowsByColumn({
                    columnId: 'day',
                    id1: cellParams1?.rowNode?.id,
                    id2: cellParams2?.rowNode?.id,
                    rows,
                });
            },
        },
        {
            display: 'flex',
            field: 'hour',
            headerName: t('Hour'),
            minWidth: 60,
            flex: 1,
            sortComparator: (v1, v2, cellParams1, cellParams2) => {
                if (v1 === v2) return 0;
                return compareRowsByColumn({
                    columnId: 'hour',
                    id1: cellParams1?.rowNode?.id,
                    id2: cellParams2?.rowNode?.id,
                    rows,
                });
            },
        },
        {
            display: 'flex',
            field: 'missingEnergy',
            headerName: t('Missing energy').concat(' (kWh)'),
            minWidth: 200,
            flex: 1,
            sortComparator: (v1, v2, cellParams1, cellParams2) => {
                if (v1 === v2) return 0;
                return compareRowsByColumn({
                    columnId: 'missingEnergy',
                    id1: cellParams1?.rowNode?.id,
                    id2: cellParams2?.rowNode?.id,
                    rows,
                });
            },
            valueGetter: (_, row) =>
                numberFormat(row.missingEnergy, {
                    decimals: 3,
                    locale: countryCurrencyLocale,
                    style: 'decimal',
                }),
        },
        {
            display: 'flex',
            field: 'missingPower',
            headerName: t('Missing power').concat(' (kW)'),
            minWidth: 200,
            flex: 1,
            sortComparator: (v1, v2, cellParams1, cellParams2) => {
                if (v1 === v2) return 0;
                return compareRowsByColumn({
                    columnId: 'missingPower',
                    id1: cellParams1?.rowNode?.id,
                    id2: cellParams2?.rowNode?.id,
                    rows,
                });
            },
            valueGetter: (_, row) =>
                numberFormat(row.missingPower, {
                    decimals: 3,
                    locale: countryCurrencyLocale,
                    style: 'decimal',
                }),
        },
    ];

    const getRowHeight = () => 'auto';
    const initialState = { pagination: { paginationModel: { pageSize: 25 } } };
    const onRowClick = (params) => {
        const { row } = params;
        handleGoToDay({
            days: [row.day || 0],
            option: 3,
            period: Number(row.period),
            weeks: [row.week - 1],
            year: Number(row.year),
        });
    };
    const slotProps = { pagination: { pageSizeOptions: [25] } };
    const slots = { pagination: CustomPagination };
    const sx = {
        '.MuiDataGrid-cell': {
            cursor: 'pointer',
        },
    };

    return (
        <DataGrid
            autoHeight
            columns={columns}
            disableColumnMenu
            getRowHeight={getRowHeight}
            initialState={initialState}
            onRowClick={onRowClick}
            rows={rows}
            slotProps={slotProps}
            slots={slots}
            sx={sx}
        />
    );
};

WarningsList.propTypes = {
    consumptionHistory: PropTypes.array,
    emptyEnergyIndexes: PropTypes.array,
    handleGoToDay: PropTypes.func,
    realIndex: PropTypes.array,
};

export default WarningsList;
