import React, { useEffect, useState } from 'react';

import PropTypes from 'prop-types';
import { DataGrid } from 'sunwise-ui';

import { CustomPagination } from 'common/components';

import { getColumnsFromSeries, getRowsFromSeries } from '../helpers';

const MeasuredEnergyTable = ({
    categories,
    countryCurrencyLocale,
    isFetching,
    series,
    viewOption,
}) => {
    const [tableContent, setTableContent] = useState({
        rows: [],
        columns: [],
    });

    useEffect(() => {
        setTableContent({
            rows: getRowsFromSeries(series, categories),
            columns: getColumnsFromSeries(
                series,
                viewOption,
                countryCurrencyLocale,
            ),
        });
    }, [series, categories]);

    const columns = tableContent.columns;
    const initialState = {
        pagination: {
            paginationModel: { pageSize: 12 },
        },
    };
    const rows = tableContent.rows;
    const slotProps = { pagination: { pageSizeOptions: [12, 24] } };
    const slots = { pagination: CustomPagination };
    const sx = {
        '& .MuiDataGrid-cell:focus': { outline: 'none' },
        my: 2,
    };

    return (
        <DataGrid
            autoHeight
            columns={columns}
            loading={isFetching}
            disableColumnMenu
            disableRowSelectionOnClick
            hideFooterSelectedRowCount
            initialState={initialState}
            pagination
            paginationMode="client"
            rows={rows}
            slotProps={slotProps}
            slots={slots}
            sx={sx}
        />
    );
};

MeasuredEnergyTable.propTypes = {
    categories: PropTypes.array,
    countryCurrencyLocale: PropTypes.string,
    isFetching: PropTypes.bool,
    series: PropTypes.array,
    viewOption: PropTypes.number,
};

export default MeasuredEnergyTable;
