import React, { useEffect, useState } from 'react';

import { push } from 'connected-react-router';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { Link as RouterLink } from 'react-router-dom';
import { compose } from 'recompose';
import { createStructuredSelector } from 'reselect';
import {
    Box,
    Button,
    Card,
    DataGrid,
    FormControl,
    Grid,
    InputLabel,
    MenuItem,
    Select,
} from 'sunwise-ui';

import TitleWithDetail from 'common/components/TitleWithDetail';
import { PERMISSION_LIST } from 'common/constants/permissionsV2';
import withPermissions from 'common/hocs/withPermissions';
import { getAgentOptions } from 'common/utils/helpers';
import { getIsOnlyContacts } from 'common/utils/helpers/session';
import { scroll } from 'common/utils/mixins';

import CompactColumns from '../../followUps/components/CompactColumns';
import * as trackingsActions from '../../trackings/actions';
import * as trackingsSelectors from '../../trackings/selectors';
import * as actions from '../actions';
import * as selectors from '../selectors';

const Container = ({
    agents,
    canView,
    contactsTrackingsData,
    fetchContactsTrackings,
    fetchUsers,
    isLoading,
    menuOptions,
    trackingsByAgent,
    users,
}) => {
    const { t } = useTranslation();
    const [agentSelected, setAgentSelected] = useState('');
    const isOnlyContacts = getIsOnlyContacts();

    useEffect(() => {
        fetchContactsTrackings();
        fetchUsers();
    }, []);

    const handleSelected = (item) => {
        if (item.value === null) {
            fetchContactsTrackings();
            setAgentSelected('');
        } else {
            trackingsByAgent(item.value);
            setAgentSelected(item.value);
        }
    };

    const columns = CompactColumns({ users });
    const getRowHeight = () => 'auto';
    const initialState = {
        pagination: { paginationModel: { pageSize: 5, page: 0 } },
    };
    const rowsPerPageOptions = [5];

    return (
        <Card
            id="card-tracking-contacts"
            sx={{ mb: 0, minHeight: '100%', width: '100%' }}
        >
            <Card.Header id="card-header-tracking-contacts">
                <Grid alignContent="center" alignItems="center" container>
                    <Grid size={{ lg: 'grow', xs: 18 }}>
                        <TitleWithDetail variant="body2">
                            {t('Follow-up', { count: 2 })}
                        </TitleWithDetail>
                    </Grid>
                    {!isOnlyContacts && (
                        <Grid size={{ lg: 5, xs: 18 }}>
                            <FormControl fullWidth size="small">
                                <InputLabel>{t('User')}</InputLabel>
                                <Select
                                    label={t('User')}
                                    onChange={(e) => handleSelected(e.target)}
                                    value={agentSelected}
                                >
                                    {getAgentOptions(agents).map(
                                        ({ label, value, disabled }) => (
                                            <MenuItem
                                                disabled={disabled}
                                                key={`agent-select-${value}-${label}`}
                                                value={value}
                                            >
                                                {label}
                                            </MenuItem>
                                        ),
                                    )}
                                </Select>
                            </FormControl>
                        </Grid>
                    )}
                    {menuOptions}
                </Grid>
            </Card.Header>

            <Card.Body
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    height: 'calc(100% - 73px)',
                    overflowY: 'auto',
                    ...scroll.object,
                }}
            >
                <DataGrid
                    autoHeight
                    columns={columns}
                    disableColumnMenu
                    disableRowSelectionOnClick
                    getRowHeight={getRowHeight}
                    initialState={initialState}
                    loading={isLoading}
                    rows={contactsTrackingsData}
                    rowsPerPageOptions={rowsPerPageOptions}
                />

                <Box my="auto" py={1}>
                    <Button
                        color="secondary"
                        component={RouterLink}
                        fullWidth
                        to="/follow-ups"
                        variant="text"
                        visible={canView}
                        sx={{ textTransform: 'none' }}
                    >
                        {t('See more follow-ups')}
                    </Button>
                </Box>
            </Card.Body>
        </Card>
    );
};

Container.propTypes = {
    agents: PropTypes.array,
    canView: PropTypes.bool,
    contactsTrackingsData: PropTypes.array,
    fetchContactsTrackings: PropTypes.func,
    fetchUsers: PropTypes.func,
    isLoading: PropTypes.bool,
    menuOptions: PropTypes.object,
    trackingsByAgent: PropTypes.func,
    users: PropTypes.array,
};

const mapStateToProps = createStructuredSelector({
    contactsTrackingsData: selectors.getContactsTrackingData,
    isLoading: selectors.getContactsTrackingDataLoading,
    users: trackingsSelectors.getUsersData,
});

const mapDispatchToProps = (dispatch) => ({
    fetchContactsTrackings: () => dispatch(actions.fetchContactsTrackings()),
    fetchUsers: () => dispatch(trackingsActions.fetchUsers()),
    redirect: (link) => dispatch(push(link)),
    trackingsByAgent: (id) => dispatch(actions.trackingsByAgent(id)),
});

export default compose(
    connect(mapStateToProps, mapDispatchToProps),
    withPermissions(PERMISSION_LIST.TRACKING_CONTACTS_PERMISSION),
)(Container);
