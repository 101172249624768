import React from 'react';

import orderBy from 'lodash/orderBy';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import { useBreakpoint } from 'common/hooks';

import BranchOfficeFormatter from './BranchOfficeFormatter';
import ContactFormatter from './ContactFormatter';
import DateFormatter from './DateFormatter';
import FinancingFormatter from './FinancingFormatter';
import NameFormatter from './NameFormatter';
import OriginFormatter from './OriginFormatter';
import PanelsNumberFormatter from './PanelsNumberFormatter';
import ProjectFormatter from './ProjectFormatter';
import RateFormatter from './RateFormatter';
import ServiceNumberFormatter from './ServiceNumberFormatter';
import StorageCapMaxFormatter from './StorageCapMaxFormatter';
import SystemSizeFormatter from './SystemSizeFormatter';
import UserFormatter from './UserFormatter';

const Columns = ({ handleClickView, hiddenColumns, orders }) => {
    const { t } = useTranslation();
    const breakpoint = useBreakpoint();

    const columnName = {
        display: 'flex',
        field: 'name',
        headerName: t('Name'),
        hide: hiddenColumns.includes('name'),
        minWidth: 250,
        order: orders?.name,
        renderCell: (params) => {
            const { row } = params;
            return (
                <NameFormatter handleClickView={handleClickView} row={row} />
            );
        },
    };

    let columns = [
        columnName,
        {
            display: 'flex',
            field: 'panels_counter',
            headerName: t('Panel', { count: 2 }),
            hide: hiddenColumns.includes('panels_counter'),
            minWidth: 120,
            order: orders?.panels_counter,
            renderCell: PanelsNumberFormatter,
        },
        {
            display: 'flex',
            field: 'read_only',
            headerName: t('Origin'),
            hide: hiddenColumns.includes('read_only'),
            minWidth: 120,
            order: orders?.read_only,
            renderCell: OriginFormatter,
            sortable: false,
        },
        {
            display: 'flex',
            field: 'system_size',
            headerName: `${t('System')} (kW)`,
            hide: hiddenColumns.includes('system_size'),
            minWidth: 120,
            order: orders?.system_size,
            renderCell: SystemSizeFormatter,
        },
        {
            display: 'flex',
            field: 'storage_cap_max',
            headerName: `${t('Storage')} (kWh)`,
            hide: hiddenColumns.includes('storage_cap_max'),
            minWidth: 120,
            order: orders?.storage_cap_max,
            renderCell: StorageCapMaxFormatter,
        },
        {
            display: 'flex',
            field: 'service_number',
            headerName: t('Service number'),
            hide: hiddenColumns.includes('service_number'),
            minWidth: 120,
            order: orders?.service_number,
            renderCell: ServiceNumberFormatter,
        },
        {
            display: 'flex',
            field: 'project',
            headerName: t('Project'),
            hide: hiddenColumns.includes('project'),
            minWidth: 250,
            order: orders?.project,
            renderCell: ProjectFormatter,
        },
        {
            display: 'flex',
            field: 'is_financing',
            headerName: t('Financing'),
            hide: hiddenColumns.includes('is_financing'),
            minWidth: 250,
            order: orders?.is_financing,
            renderCell: FinancingFormatter,
            sortable: false,
        },
        {
            display: 'flex',
            field: 'contact',
            headerName: t('Contact'),
            hide: hiddenColumns.includes('contact'),
            minWidth: 250,
            order: orders?.contact,
            renderCell: ContactFormatter,
        },
        {
            display: 'flex',
            field: 'branch_office',
            headerName: t('Branch office'),
            hide: hiddenColumns.includes('branch_office'),
            minWidth: 180,
            order: orders?.branch_office,
            renderCell: BranchOfficeFormatter,
        },
        {
            display: 'flex',
            field: 'created_by',
            headerName: t('User'),
            hide: hiddenColumns.includes('created_by'),
            minWidth: 180,
            order: orders?.created_by,
            renderCell: UserFormatter,
        },
        {
            display: 'flex',
            field: 'rate_name',
            headerName: t('Rate'),
            hide: hiddenColumns.includes('rate_name'),
            minWidth: 120,
            order: orders?.rate_name,
            renderCell: RateFormatter,
        },
        {
            display: 'flex',
            field: 'created_at',
            headerName: t('Date'),
            hide: hiddenColumns.includes('created_at'),
            minWidth: 120,
            order: orders?.created_at,
            renderCell: DateFormatter,
        },
    ];

    if (['xs', 'sm'].includes(breakpoint)) columns = [columnName];

    return orderBy(columns, ['order'], ['asc']);
};

Columns.propTypes = {
    handleClickView: PropTypes.func,
    hiddenColumns: PropTypes.array,
    orders: PropTypes.object,
};

export default Columns;
