import React, { useEffect } from 'react';

import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { types } from 'sunwise-template-core';
import { Box, Grid, DataGrid } from 'sunwise-ui';

import { CustomPagination } from 'common/components';
import CollapsedBreadcrumbs from 'common/components/CollapsedBreadcrumbs';
import { TABLE_PAGE_SIZE_OPTIONS } from 'common/constants';

import * as templateLibraryActions from '../../../templateLibrary/actions';
import * as templateLibrarySelectors from '../../../templateLibrary/selectors';
import * as actions from '../../actions';
import * as selectors from '../../selectors';

import CustomTemplateColumns from './CustomTemplateColumns';
import Filter from './Filter';
import TemplatePagesSelector from './TemplatePagesSelector';

const CustomTemplatesTab = ({
    cleanTemplatePreview,
    customTemplate,
    fetchTemplatePreview,
    filterTemplates,
    handleSelectTemplate,
    isFetchingCustomTemplate,
    languagesDictionary,
    selectedPages,
    selectedTemplate,
    setSelectedPages,
    setSelectedTemplate,
    templateBaseType,
    templatePreviewData,
    templatePreviewPages,
    templatesData,
    isFetchingTemplatePreview,
}) => {
    const { t } = useTranslation();
    useEffect(() => {
        filterTemplates({ is_base: false, pageSize: 100 });
    }, []);

    const filteredItems = templatesData.filter(
        (item) =>
            item.external_uuid !== customTemplate?.id &&
            item.total_pages >= 1 &&
            item.language === customTemplate?.language,
    );

    const columns = CustomTemplateColumns({
        isForSummaryTemplates: templateBaseType === types.MULTIPROPOSAL_TYPE,
        languagesDictionary,
    });
    const getRowHeight = () => 'auto';
    const initialState = {
        pagination: {
            paginationModel: { pageSize: TABLE_PAGE_SIZE_OPTIONS[0] },
        },
    };
    const onRowSelectionModelChange = (newSelectionModel) => {
        if (isFetchingTemplatePreview) return;
        const selectedIDs = new Set(newSelectionModel);
        const selectedRowData = filteredItems.find((row) =>
            selectedIDs.has(row.id.toString()),
        );

        if (selectedRowData) {
            fetchTemplatePreview(selectedRowData.external_uuid);
            handleSelectTemplate({
                id: selectedRowData.external_uuid,
                pages: selectedRowData.total_pages,
                title: selectedRowData.title,
            });
        }
    };
    const pageSizeOptions = TABLE_PAGE_SIZE_OPTIONS;
    const slotProps = { pagination: { pageSizeOptions } };
    const slots = { pagination: CustomPagination };

    return (
        <>
            <Box sx={{ display: templatePreviewData ? 'none' : '' }}>
                <Box sx={{ mb: 2 }}>
                    <Filter filterTemplates={filterTemplates} />
                </Box>

                <DataGrid
                    autoHeight
                    columns={columns}
                    disableColumnMenu
                    getRowHeight={getRowHeight}
                    initialState={initialState}
                    loading={isFetchingCustomTemplate}
                    onRowSelectionModelChange={onRowSelectionModelChange}
                    pagination
                    rows={filteredItems}
                    slotProps={slotProps}
                    slots={slots}
                />
            </Box>

            {selectedTemplate &&
                templatePreviewData &&
                templatePreviewPages && (
                    <Grid container>
                        <Grid size={18}>
                            <CollapsedBreadcrumbs
                                current={selectedTemplate?.title}
                                locations={[
                                    {
                                        action: () => {
                                            if (templatePreviewData) {
                                                cleanTemplatePreview();
                                                setSelectedTemplate(null);
                                            }
                                        },
                                        label: t('Template', { count: 2 }),
                                    },
                                ]}
                            />
                        </Grid>

                        <TemplatePagesSelector
                            filterLanguageTammplates={false}
                            language={customTemplate.language}
                            selectedPages={selectedPages}
                            setSelectedPages={setSelectedPages}
                            templatePreviewPages={templatePreviewPages}
                        />
                    </Grid>
                )}
        </>
    );
};

const mapStateToProps = createStructuredSelector({
    customTemplate: selectors.getDataFetchCustomTemplate,
    isFetchingCustomTemplate: selectors.getIsFetchingCustomTemplate,
    isFetchingTemplatePreview: selectors.getTemplatePreviewIsFetching,
    languagesDictionary: templateLibrarySelectors.getLanguagesDictionary,
    templatePreviewData: selectors.getTemplatePreviewData,
    templatePreviewPages: selectors.getTemplatePreviewPages,
    templatesData: templateLibrarySelectors.getTemplatesData,
});

const mapDispatchToProps = (dispatch) => ({
    cleanTemplatePreview: () => dispatch(actions.cleanTemplatePreview()),
    filterTemplates: (filterData) =>
        dispatch(templateLibraryActions.filterTemplates(filterData)),
    fetchTemplatePreview: (uuid) =>
        dispatch(actions.fetchTemplatePreview(uuid)),
});

CustomTemplatesTab.propTypes = {
    cleanTemplatePreview: PropTypes.func,
    customTemplate: PropTypes.object,
    fetchTemplatePreview: PropTypes.func,
    filterTemplates: PropTypes.func,
    handleSelectTemplate: PropTypes.func,
    isFetchingCustomTemplate: PropTypes.bool,
    isFetchingTemplatePreview: PropTypes.bool,
    languagesDictionary: PropTypes.object,
    selectedPages: PropTypes.array,
    selectedTemplate: PropTypes.object,
    setSelectedPages: PropTypes.func,
    setSelectedTemplate: PropTypes.func,
    templateBaseType: PropTypes.number,
    templatePreviewData: PropTypes.object,
    templatePreviewPages: PropTypes.array,
    templatesData: PropTypes.array,
};

export default connect(mapStateToProps, mapDispatchToProps)(CustomTemplatesTab);
