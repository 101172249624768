import React, { useEffect, useState, useRef } from 'react';

import { debounce, isEqual, isNull } from 'lodash';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Card, DataGrid } from 'sunwise-ui';

import { CustomPagination } from 'common/components';
import BulkOperationsCard from 'common/components/BulkOperationsCard';
import { DEBOUNCE_TIME_1500, TABLE_PAGE_SIZE_OPTIONS } from 'common/constants';
import CustomColumns from 'common/modules/customColumns';
import { getOrderedColumns, getHiddenColumns } from 'common/utils/helpers';

import { COLUMNS_CONFIG } from '../constants';

import ProductColumns from './ProductColumns';

const ListItems = ({
    canDelete,
    canModify,
    filterData,
    filterItems,
    handleClickBulkItems,
    handleClickUpdate,
    handleSaveRow,
    isFetching,
    items,
    paginationData,
    prepareDuplicate,
    setFilterData,
    tableSettings,
}) => {
    const { t } = useTranslation();
    const [currentFilterData, setCurrentFilterData] = useState(filterData);
    const [rowCount, setRowCount] = useState(paginationData?.rowCount || 0);
    const [rowModesModel, setRowModesModel] = useState({});
    const [selectedRowId, setSelectedRowId] = useState(null);
    const [selectionModel, setSelectionModel] = useState([]);

    useEffect(() => {
        if (!paginationData) return;
        setRowCount((prevRowCount) =>
            paginationData?.total_rows !== undefined
                ? paginationData?.total_rows
                : prevRowCount,
        );
    }, [paginationData?.total_rows, setRowCount]);

    useEffect(() => {
        if (isEqual(currentFilterData, filterData)) return;
        handleOnFilter(filterData);
        setCurrentFilterData(filterData);
    }, [filterData]);

    const handleOnFilter = useRef(
        debounce((filter) => filterItems(filter), DEBOUNCE_TIME_1500),
    ).current;

    const handleOnChangeSearch = (event) =>
        setFilterData((prev) => ({
            ...prev,
            page: 0,
            searchText: event.target.value,
        }));

    const handleOnSelectStatus = (value) =>
        setFilterData((prev) => ({ ...prev, page: 0, status: value }));

    const prefix = t('Plan', {
        count: selectionModel.length,
    }).toLocaleLowerCase();

    const checkboxSelection = canDelete;
    const columns = ProductColumns({
        canDelete,
        canModify,
        filterData,
        handleClickBulkItems,
        handleClickUpdate,
        hiddenColumns: getHiddenColumns(tableSettings),
        orders: getOrderedColumns(tableSettings),
        prepareDuplicate,
        rowModesModel,
        selectedRowId,
        setRowModesModel,
        setSelectedRowId,
    });
    const columnVisibilityModel = columns.reduce(
        (acc, column) => ({ ...acc, [column.field]: !column.hide }),
        {},
    );
    const getRowHeight = () => 'auto';
    const initialState = {
        pagination: {
            paginationModel: { pageSize: TABLE_PAGE_SIZE_OPTIONS[0] },
        },
    };
    const onPaginationModelChange = (model) =>
        setFilterData({ ...filterData, ...model });
    const onRowEditStart = (params, event) => {
        const id = params.row.id;
        if (isNull(selectedRowId)) setSelectedRowId(id);
        else event.defaultMuiPrevented = true;
        return false;
    };
    const onRowEditStop = (_, event) => {
        event.defaultMuiPrevented = true;
    };
    const onRowModesModelChange = (model) => setRowModesModel(model);
    const onRowSelectionModelChange = (model) => setSelectionModel(model);
    const onSortModelChange = (model) => {
        if (!model[0]) return;
        setFilterData((prev) => ({
            ...prev,
            orderBy: model[0].field.toLocaleLowerCase(),
            sortBy: model[0].sort,
        }));
    };
    const pageSizeOptions = TABLE_PAGE_SIZE_OPTIONS;
    const paginationModel = {
        page: paginationData?.current_page - 1 || 0,
        pageSize: filterData?.pageSize || TABLE_PAGE_SIZE_OPTIONS[0],
    };
    const processRowUpdate = (newRow) => {
        handleSaveRow(newRow);
        return newRow;
    };
    const slotProps = { pagination: { pageSizeOptions } };
    const slots = { pagination: CustomPagination };

    return (
        <>
            <BulkOperationsCard
                canDelete={canDelete}
                filterData={filterData}
                handleClickBulkItems={handleClickBulkItems}
                handleOnChangeSearch={handleOnChangeSearch}
                handleOnSelectStatus={handleOnSelectStatus}
                prefix={prefix}
                selectionModel={selectionModel}
                setSelectionModel={setSelectionModel}
            />

            <Card>
                <Card.Body>
                    <CustomColumns.Container
                        columnsConfig={COLUMNS_CONFIG}
                        tableKey="plans"
                    />

                    <DataGrid
                        autoHeight
                        checkboxSelection={checkboxSelection}
                        columnVisibilityModel={columnVisibilityModel}
                        columns={columns}
                        disableColumnMenu
                        disableRowSelectionOnClick
                        editMode="row"
                        getRowHeight={getRowHeight}
                        initialState={initialState}
                        loading={isFetching}
                        onPaginationModelChange={onPaginationModelChange}
                        onRowEditStart={onRowEditStart}
                        onRowEditStop={onRowEditStop}
                        onRowModesModelChange={onRowModesModelChange}
                        onRowSelectionModelChange={onRowSelectionModelChange}
                        onSortModelChange={onSortModelChange}
                        pageSizeOptions={pageSizeOptions}
                        pagination
                        paginationMode="server"
                        paginationModel={paginationModel}
                        processRowUpdate={processRowUpdate}
                        rowCount={rowCount}
                        rowModesModel={rowModesModel}
                        rows={items}
                        selectionModel={selectionModel}
                        slotProps={slotProps}
                        slots={slots}
                        sortingMode="server"
                    />
                </Card.Body>
            </Card>
        </>
    );
};

ListItems.propTypes = {
    canDelete: PropTypes.bool,
    canModify: PropTypes.bool,
    filterData: PropTypes.object,
    filterItems: PropTypes.func,
    handleClickBulkItems: PropTypes.func,
    handleClickUpdate: PropTypes.func,
    handleSaveRow: PropTypes.func,
    isFetching: PropTypes.bool,
    items: PropTypes.array,
    paginationData: PropTypes.object,
    prepareDuplicate: PropTypes.func,
    setFilterData: PropTypes.func,
    tableSettings: PropTypes.array,
};

export default ListItems;
