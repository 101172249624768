import React, { useEffect, useState, useRef } from 'react';

import { debounce, isEqual, isNull } from 'lodash';
import PropTypes from 'prop-types';
import { Card, DataGrid } from 'sunwise-ui';

import {
    CustomPagination,
    ProductsBulkOperationsCard,
    ProductColumns,
} from 'common/components';
import { DEBOUNCE_TIME_1500, TABLE_PAGE_SIZE_OPTIONS } from 'common/constants';
import CustomColumns from 'common/modules/customColumns';
import { getOrderedColumns, getHiddenColumns } from 'common/utils/helpers';

import { COLUMNS_CONFIG } from '../constants';

const ListItems = ({
    canDelete,
    canModify,
    downloadFile,
    filterData,
    filterItems,
    handleClickBulkItems,
    handleClickDownloadDatasheet,
    handleClickUpdate,
    handleClickUpdateMargin,
    handleSaveRow,
    isFetching,
    items,
    loadingContext,
    paginationData,
    prepareUploadFile,
    setFilterData,
    tableSettings,
}) => {
    const [rowCount, setRowCount] = useState(paginationData?.rowCount || 0);
    const [rowModesModel, setRowModesModel] = useState({});
    const [selectedRowId, setSelectedRowId] = useState(null);
    const [selectionModel, setSelectionModel] = useState([]);
    const [currentFilterData, setCurrentFilterData] = useState(filterData);

    useEffect(() => {
        if (paginationData)
            setRowCount((prevRowCount) =>
                paginationData?.total_rows !== undefined
                    ? paginationData?.total_rows
                    : prevRowCount,
            );
    }, [paginationData?.total_rows, setRowCount]);

    useEffect(() => {
        if (!isEqual(currentFilterData, filterData)) {
            handleOnFilter(filterData);
            setCurrentFilterData(filterData);
        }
    }, [filterData]);

    const handleOnFilter = useRef(
        debounce((filter) => filterItems(filter), DEBOUNCE_TIME_1500),
    ).current;

    const handleOnChangeSearch = (event) =>
        setFilterData({
            ...filterData,
            page: 0,
            searchText: event.target.value,
        });

    const handleOnSelectStatus = (status) =>
        setFilterData({ ...filterData, page: 0, status });

    const checkboxSelection = canDelete || canModify ? true : false;
    const columns = ProductColumns({
        canDelete,
        canModify,
        filterData,
        handleClickBulkItems,
        handleClickDownloadDatasheet,
        handleClickUpdate,
        hiddenColumns: getHiddenColumns(tableSettings),
        loadingContext,
        orders: getOrderedColumns(tableSettings),
        productKey: 'inverters',
        rowModesModel,
        selectedRowId,
        setRowModesModel,
        setSelectedRowId,
    });
    const columnVisibilityModel = columns.reduce(
        (acc, column) => ({ ...acc, [column.field]: !column.hide }),
        {},
    );
    const getRowHeight = () => 'auto';
    const initialState = {
        pagination: {
            paginationModel: { pageSize: TABLE_PAGE_SIZE_OPTIONS[0] },
        },
    };
    const onPaginationModelChange = (model) =>
        setFilterData({ ...filterData, ...model });
    const onRowEditStart = (params, event) => {
        const id = params.row.id;
        if (isNull(selectedRowId)) setSelectedRowId(id);
        else event.defaultMuiPrevented = true;
        return false;
    };
    const onRowEditStop = (_, event) => {
        event.defaultMuiPrevented = true;
    };
    const onRowModesModelChange = (model) => setRowModesModel(model);
    const onRowSelectionModelChange = (model) => setSelectionModel(model);
    const onSortModelChange = (sortModel) => {
        if (!sortModel[0]) return;
        setFilterData({
            ...filterData,
            orderBy: sortModel[0].field.toLocaleLowerCase(),
            sortBy: sortModel[0].sort,
        });
    };
    const pageSizeOptions = TABLE_PAGE_SIZE_OPTIONS;
    const paginationModel = {
        page: paginationData?.current_page - 1 || 0,
        pageSize: filterData?.pageSize || TABLE_PAGE_SIZE_OPTIONS[0],
    };
    const processRowUpdate = (newRow) => {
        handleSaveRow(newRow);
        return newRow;
    };
    const slotProps = { pagination: { pageSizeOptions } };
    const slots = { pagination: CustomPagination };

    return (
        <>
            <ProductsBulkOperationsCard
                canDelete={canDelete}
                canModify={canModify}
                downloadFile={downloadFile}
                filterData={filterData}
                handleClickBulkItems={handleClickBulkItems}
                handleClickUpdateMargin={handleClickUpdateMargin}
                handleOnChangeSearch={handleOnChangeSearch}
                handleOnSelectStatus={handleOnSelectStatus}
                prepareUploadFile={prepareUploadFile}
                selectionModel={selectionModel}
                setSelectionModel={setSelectionModel}
            />

            <Card>
                <Card.Body>
                    <CustomColumns.Container
                        columnsConfig={COLUMNS_CONFIG}
                        tableKey="inverters"
                    />

                    <DataGrid
                        autoHeight
                        checkboxSelection={checkboxSelection}
                        columnVisibilityModel={columnVisibilityModel}
                        columns={columns}
                        disableColumnMenu
                        disableRowSelectionOnClick
                        editMode="row"
                        getRowHeight={getRowHeight}
                        initialState={initialState}
                        loading={isFetching}
                        onPaginationModelChange={onPaginationModelChange}
                        onRowEditStart={onRowEditStart}
                        onRowEditStop={onRowEditStop}
                        onRowModesModelChange={onRowModesModelChange}
                        onRowSelectionModelChange={onRowSelectionModelChange}
                        onSortModelChange={onSortModelChange}
                        pageSizeOptions={pageSizeOptions}
                        pagination
                        paginationMode="server"
                        paginationModel={paginationModel}
                        processRowUpdate={processRowUpdate}
                        rowCount={rowCount}
                        rowModesModel={rowModesModel}
                        rows={items}
                        selectionModel={selectionModel}
                        slotProps={slotProps}
                        slots={slots}
                        sortingMode="server"
                    />
                </Card.Body>
            </Card>
        </>
    );
};

ListItems.propTypes = {
    canDelete: PropTypes.bool,
    canModify: PropTypes.bool,
    downloadFile: PropTypes.func,
    filterData: PropTypes.object,
    filterItems: PropTypes.func,
    handleClickBulkItems: PropTypes.func,
    handleClickDownloadDatasheet: PropTypes.func,
    handleClickUpdate: PropTypes.func,
    handleClickUpdateMargin: PropTypes.func,
    handleSaveRow: PropTypes.func,
    isFetching: PropTypes.bool,
    items: PropTypes.array,
    loadingContext: PropTypes.object,
    paginationData: PropTypes.object,
    prepareUploadFile: PropTypes.func,
    setFilterData: PropTypes.func,
    tableSettings: PropTypes.array,
};

export default ListItems;
