import React, { useRef } from 'react';

import { debounce, isEqual } from 'lodash';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import {
    BottomActions,
    Box,
    Button,
    Card,
    DataGrid,
    Typography,
} from 'sunwise-ui';

import { CustomPagination } from 'common/components';
import { DEBOUNCE_TIME_1500 } from 'common/constants';

import ContactColumns from './ContactColumns';
import ContactsFilter from './ContactsFilter';

const ContactsSection = ({
    agents,
    contacts,
    disabled,
    filterData,
    filterItems,
    isFetching,
    onCancelSelection,
    onConfirmSelection,
    paginationData,
    selectedContact,
    setFilterData,
    setSelectedContact,
}) => {
    const { t } = useTranslation();

    const handleOnFilter = useRef(
        debounce((filter) => filterItems(filter), DEBOUNCE_TIME_1500),
    ).current;

    const handleFilterNewData = (newFilterData) => {
        if (disabled || isEqual(newFilterData, filterData)) return;
        setFilterData(newFilterData);
        handleOnFilter(newFilterData);
        setSelectedContact(null);
    };

    const handleOnChangeFilter = (key, value) =>
        handleFilterNewData((prev) => ({ ...prev, page: 0, [key]: value }));

    const PAGE_SIZE_OPTIONS = [15, 25, 50, 75, 100];
    const page = paginationData?.current_page - 1 || 0;
    const pageSize = filterData?.pageSize;

    const columns = ContactColumns();
    const initialState = {
        pagination: { paginationModel: { pageSize: PAGE_SIZE_OPTIONS[0] } },
    };
    const onPaginationModelChange = (model) => {
        if (model.pageSize !== pageSize)
            handleOnChangeFilter('pageSize', model.pageSize);
        if (model.page !== page) handleOnChangeFilter('page', model.page + 1);
    };
    const onRowClick = (params) => setSelectedContact(params?.row);
    const onSortModelChange = (model) => {
        if (!model[0]) return;
        handleFilterNewData((prev) => ({
            ...prev,
            orderBy: model[0].field.toLocaleLowerCase(),
            sortBy: model[0].sort,
        }));
    };
    const paginationModel = {
        page: paginationData?.current_page - 1 || 0,
        pageSize: filterData?.pageSize || PAGE_SIZE_OPTIONS[0],
    };
    const rowCount = paginationData?.total_rows || 0;
    const selectionModel = [selectedContact?.id];
    const slotProps = { pagination: { pageSizeOptions: PAGE_SIZE_OPTIONS } };
    const slots = { pagination: CustomPagination };
    const sx = {
        '& .MuiDataGrid-row': { cursor: 'pointer' },
        '& .MuiDataGrid-cell:focus': { outline: 'none' },
    };

    return (
        <>
            <Typography variant="h6" sx={{ my: 2, ml: 1 }}>
                {t('Select a contact')}
            </Typography>

            <ContactsFilter
                agents={agents}
                filterData={filterData}
                handleOnChangeFilter={handleOnChangeFilter}
            />

            <Card>
                <Card.Body>
                    <DataGrid
                        autoHeight
                        columns={columns}
                        disableColumnMenu
                        hideFooterSelectedRowCount
                        initialState={initialState}
                        loading={isFetching}
                        onPaginationModelChange={onPaginationModelChange}
                        onRowClick={onRowClick}
                        onSortModelChange={onSortModelChange}
                        pagination
                        paginationMode="server"
                        paginationModel={paginationModel}
                        rowCount={rowCount}
                        rows={contacts}
                        selectionModel={selectionModel}
                        slotProps={slotProps}
                        slots={slots}
                        sortingMode="server"
                        sx={sx}
                    />
                </Card.Body>
            </Card>

            <BottomActions>
                <Box display="flex" gap={1} mt={2}>
                    <Button
                        color="secondary"
                        disabled={disabled}
                        onClick={onCancelSelection}
                        variant="outlined"
                    >
                        {t('Cancel')}
                    </Button>

                    <Button
                        disabled={!selectedContact || disabled}
                        onClick={onConfirmSelection}
                    >
                        {t('Next')}
                    </Button>
                </Box>
            </BottomActions>
        </>
    );
};

ContactsSection.propTypes = {
    agents: PropTypes.array,
    contacts: PropTypes.array,
    disabled: PropTypes.bool,
    filterData: PropTypes.object,
    filterItems: PropTypes.func,
    isFetching: PropTypes.bool,
    onCancelSelection: PropTypes.func,
    onConfirmSelection: PropTypes.func,
    paginationData: PropTypes.object,
    selectedContact: PropTypes.object,
    setFilterData: PropTypes.func,
    setSelectedContact: PropTypes.func,
};

export default ContactsSection;
