import React from 'react';

import LockIcon from '@mui/icons-material/Lock';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Box, Typography } from 'sunwise-ui';

import ActionsFormatter from './ActionsFormatter';

const Columns = ({
    canDelete,
    canModify,
    handleClickDelete,
    handleClickUpdate,
}) => {
    const { t } = useTranslation();

    let columns = [
        {
            display: 'flex',
            field: 'name',
            flex: 1,
            headerName: t('Role name'),
            sortable: false,
            renderCell: (params) => {
                const { row } = params;
                const isDefault = row?.is_system_role;
                return (
                    <Box display="flex" gap={1}>
                        <Typography fontWeight="bold" variant="caption">
                            {row.name}
                        </Typography>
                        {isDefault && (
                            <LockIcon
                                fontSize="small"
                                sx={{ color: '#E0E0E0' }}
                            />
                        )}
                    </Box>
                );
            },
        },
        {
            display: 'flex',
            field: 'used_by',
            flex: 1,
            headerName: t('Used by'),
            sortable: false,
            renderCell: (params) => {
                const { row } = params;
                if (row.users === 0) return '---';
                return (
                    <Typography fontWeight="bold" variant="caption">
                        {row.users} {t('User', { count: row.users })}
                    </Typography>
                );
            },
        },
        {
            align: 'left',
            field: '',
            flex: 1,
            renderCell: (params) => {
                const { row } = params;
                return (
                    <ActionsFormatter
                        canDelete={canDelete}
                        canModify={canModify}
                        handleClickDelete={handleClickDelete}
                        handleClickUpdate={handleClickUpdate}
                        row={row}
                    />
                );
            },
            sortable: false,
        },
    ];

    return columns;
};

Columns.propTypes = {
    canDelete: PropTypes.bool,
    canModify: PropTypes.bool,
    filterData: PropTypes.object,
    handleChangeSwitch: PropTypes.func,
    handleClickDelete: PropTypes.func,
    handleClickProduct: PropTypes.func,
    handleClickUpdate: PropTypes.func,
    hiddenColumns: PropTypes.array,
    orders: PropTypes.object,
    rowModesModel: PropTypes.object,
    selectedRowId: PropTypes.func,
    setRowModesModel: PropTypes.func,
    setSelectedRowId: PropTypes.func,
};

export default Columns;
