import React, { useEffect, useRef, useState } from 'react';

import debounce from 'lodash/debounce';
import PropTypes from 'prop-types';
import { Card, DataGrid } from 'sunwise-ui';

import CustomPagination from 'common/components/CustomPagination';
import { DEBOUNCE_TIME_1500, TABLE_PAGE_SIZE_OPTIONS } from 'common/constants';
import CustomColumns from 'common/modules/customColumns';
import { getOrderedColumns, getHiddenColumns } from 'common/utils/helpers';

import { COLUMNS_CONFIG } from '../constants';

import BulkOperationsCard from './BulkOperationsCard';
import Columns from './Columns';

const ListItems = ({
    canModify,
    filterData,
    filterItems,
    handleClickBulkItems,
    handleClickUpdateTracking,
    isFetching,
    items,
    paginationData,
    setFilterData,
    tableSettings,
    users,
}) => {
    const [rowCount, setRowCount] = useState(paginationData?.rowCount || 0);
    const [rowModesModel, setRowModesModel] = useState({});
    const [selectionModel, setSelectionModel] = useState([]);

    useEffect(() => {
        if (paginationData)
            setRowCount((prevRowCount) =>
                paginationData?.total_rows !== undefined
                    ? paginationData?.total_rows
                    : prevRowCount,
            );
    }, [paginationData?.total_rows, setRowCount]);

    useEffect(() => {
        handleOnFilter(filterData);
    }, [filterData]);

    const callback = () => setSelectionModel([]);

    const handleOnFilter = useRef(
        debounce((filter) => filterItems(filter), DEBOUNCE_TIME_1500),
    ).current;

    const handleOnSelectStatus = (item) =>
        setFilterData({ ...filterData, completed: item.value, page: 0 });

    const handleOnSelectUser = (item) =>
        setFilterData({ ...filterData, userId: item.value, page: 0 });

    const checkboxSelection = canModify ? true : false;
    const columns = Columns({
        callback,
        handleClickBulkItems,
        hiddenColumns: getHiddenColumns(tableSettings),
        orders: getOrderedColumns(tableSettings),
        users,
    });
    const columnVisibilityModel = columns.reduce(
        (acc, column) => ({ ...acc, [column.field]: !column.hide }),
        {},
    );
    const experimentalFeatures = { newEditingApi: true };
    const getRowHeight = () => 'auto';
    const initialState = {
        pagination: {
            paginationModel: { pageSize: TABLE_PAGE_SIZE_OPTIONS[0] },
        },
    };
    const onPaginationModelChange = (model) =>
        setFilterData({ ...filterData, ...model });
    const onRowModesModelChange = (model) => setRowModesModel(model);
    const onRowSelectionModelChange = (model) => setSelectionModel(model);
    const onSortModelChange = (sortModel) => {
        if (!sortModel[0]) return;
        setFilterData({
            ...filterData,
            orderBy: sortModel[0].field.toLocaleLowerCase(),
            sortBy: sortModel[0].sort,
        });
    };
    const pageSizeOptions = TABLE_PAGE_SIZE_OPTIONS;
    const paginationModel = {
        page: paginationData?.current_page - 1 || 0,
        pageSize: filterData?.pageSize || TABLE_PAGE_SIZE_OPTIONS[0],
    };
    const slotProps = { pagination: { pageSizeOptions } };
    const slots = { pagination: CustomPagination };

    return (
        <>
            <BulkOperationsCard
                callback={callback}
                filterData={filterData}
                handleClickBulkItems={handleClickBulkItems}
                handleClickUpdateTracking={handleClickUpdateTracking}
                handleOnSelectStatus={handleOnSelectStatus}
                handleOnSelectUser={handleOnSelectUser}
                selectionModel={selectionModel}
                setSelectionModel={setSelectionModel}
                users={users}
            />
            <Card>
                <Card.Body>
                    <CustomColumns.Container
                        columnsConfig={COLUMNS_CONFIG}
                        tableKey="trackigns"
                    />

                    <DataGrid
                        autoHeight
                        checkboxSelection={checkboxSelection}
                        columnVisibilityModel={columnVisibilityModel}
                        columns={columns}
                        disableColumnMenu
                        disableRowSelectionOnClick
                        experimentalFeatures={experimentalFeatures}
                        getRowHeight={getRowHeight}
                        initialState={initialState}
                        loading={isFetching}
                        onPaginationModelChange={onPaginationModelChange}
                        onRowModesModelChange={onRowModesModelChange}
                        onRowSelectionModelChange={onRowSelectionModelChange}
                        onSortModelChange={onSortModelChange}
                        pageSizeOptions={pageSizeOptions}
                        pagination
                        paginationMode="server"
                        paginationModel={paginationModel}
                        rowCount={rowCount}
                        rowModesModel={rowModesModel}
                        rows={items}
                        selectionModel={selectionModel}
                        slotProps={slotProps}
                        slots={slots}
                        sortingMode="server"
                    />
                </Card.Body>
            </Card>
        </>
    );
};

ListItems.propTypes = {
    canModify: PropTypes.bool,
    filterData: PropTypes.object,
    filterItems: PropTypes.func,
    handleClickBulkItems: PropTypes.func,
    handleClickUpdateTracking: PropTypes.func,
    isFetching: PropTypes.bool,
    items: PropTypes.array,
    paginationData: PropTypes.object,
    setFilterData: PropTypes.func,
    tableSettings: PropTypes.array,
    users: PropTypes.array,
};

export default ListItems;
