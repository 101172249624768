import React from 'react';

import orderBy from 'lodash/orderBy';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Typography } from 'sunwise-ui';

import { useBreakpoint } from 'common/hooks';
import { trackingTypeToLabel } from 'common/utils/helpers';

import ContactFormatter from './ContactFormatter';
import DateFormatter from './DateFormatter';
import DescriptionFormatter from './DescriptionFormatter';
import ProjectFormatter from './ProjectFormatter';
import StatusFormatter from './StatusFormatter';
import UserFormatter from './UserFormatter';

const Columns = ({
    callback,
    handleClickBulkItems,
    hiddenColumns,
    orders,
    users,
}) => {
    const { t } = useTranslation();
    const breakpoint = useBreakpoint();

    const columnName = {
        display: 'flex',
        field: 'contact',
        headerName: t('Contact'),
        hide: hiddenColumns.includes('contact'),
        minWidth: 250,
        order: orders?.contact,
        renderCell: ContactFormatter,
    };

    let columns = [
        {
            display: 'flex',
            field: 'status',
            headerName: t('Status'),
            hide: hiddenColumns.includes('status'),
            minWidth: 100,
            order: orders?.status,
            renderCell: (params) => {
                const { row } = params;
                return (
                    <StatusFormatter
                        callback={callback}
                        handleClickBulkItems={handleClickBulkItems}
                        row={row}
                    />
                );
            },
        },
        {
            display: 'flex',
            field: 'description',
            headerName: t('Follow-up'),
            hide: hiddenColumns.includes('description'),
            minWidth: 350,
            order: orders?.description,
            renderCell: (params) => {
                const { row } = params;
                return (
                    <DescriptionFormatter
                        description={row?.description}
                        isCompleted={row?.completed}
                        users={users}
                    />
                );
            },
        },
        columnName,
        {
            display: 'flex',
            field: 'type',
            headerName: t('Type'),
            hide: hiddenColumns.includes('type'),
            minWidth: 100,
            order: orders?.type,
            renderCell: (params) => {
                const { row } = params;
                return (
                    <Typography variant="caption">
                        {trackingTypeToLabel(row?.tracking_type)}
                    </Typography>
                );
            },
        },
        {
            display: 'flex',
            field: 'objective',
            headerName: t('Objective'),
            hide: hiddenColumns.includes('objective'),
            minWidth: 120,
            order: orders?.objective,
            renderCell: (params) => {
                const { row } = params;
                return (
                    <Typography variant="caption">
                        {row?.tracking_purpose?.name}
                    </Typography>
                );
            },
        },
        {
            display: 'flex',
            field: 'user',
            headerName: t('User'),
            hide: hiddenColumns.includes('user'),
            minWidth: 250,
            order: orders?.user,
            renderCell: UserFormatter,
        },
        {
            display: 'flex',
            field: 'notification_at',
            headerName: t('Date'),
            hide: hiddenColumns.includes('notification_at'),
            minWidth: 200,
            order: orders?.notification_at,
            renderCell: DateFormatter,
        },
        {
            display: 'flex',
            field: 'project',
            headerName: t('Project'),
            hide: hiddenColumns.includes('project'),
            minWidth: 200,
            order: orders?.project,
            renderCell: ProjectFormatter,
        },
    ];

    if (breakpoint === 'xs')
        columns = [
            columnName,
            {
                display: 'flex',
                field: 'notification_at',
                headerName: t('Date'),
                hide: hiddenColumns.includes('notification_at'),
                minWidth: 200,
                order: orders?.notification_at,
                renderCell: DateFormatter,
            },
        ];

    return orderBy(columns, ['order'], ['asc']);
};

Columns.propTypes = {
    callback: PropTypes.func,
    handleClickBulkItems: PropTypes.func,
    hiddenColumns: PropTypes.array,
    orders: PropTypes.object,
    users: PropTypes.array,
};

export default Columns;
