import React, { useEffect, useState } from 'react';

import isEmpty from 'lodash/isEmpty';
import PropTypes from 'prop-types';
import { useWatch } from 'react-hook-form';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { DataGrid } from 'sunwise-ui';

import { CustomPagination } from 'common/components';
import { TABLE_PAGE_SIZE_OPTIONS } from 'common/constants';
import * as multiBranchesSelectors from 'common/modules/multiBranches/selectors';

import * as actions from '../../actions';
import * as selectors from '../../selectors';

import CustomTemplateColumns from './CustomTemplateColumns';
import Filter from './Filter';

const CustomTemplatesTab = ({
    branchOfficesDictionary,
    companyUsersData,
    control,
    filterCustomBaseTemplates,
    handleSelectTemplate,
    isForSummaryTemplates,
    items,
    languagesDictionary,
    loading,
    paginationData,
}) => {
    const [language] = useWatch({ control, name: ['language'] });
    const [filterData, setFilterData] = useState({
        language,
        orderBy: 'created_at',
        page: 0,
        pageSize: 25,
        searchText: '',
        sortBy: 'desc',
        status: 'active',
    });
    const [rowCount, setRowCount] = useState(paginationData?.rowCount || 0);
    const [selectionModel, setSelectionModel] = useState([]);

    useEffect(() => {
        if (isEmpty(paginationData)) return;
        setRowCount((prev) =>
            paginationData?.total_rows !== undefined
                ? paginationData?.total_rows
                : prev,
        );
    }, [paginationData?.total_rows, setRowCount]);

    useEffect(() => {
        if (!filterData) return;
        filterCustomBaseTemplates(filterData);
    }, [filterData]);

    useEffect(() => {
        handleSelectTemplate({ id: selectionModel[0], locked: 0 });
    }, [selectionModel]);

    const columns = CustomTemplateColumns({
        branchOfficesDictionary,
        companyUsersData,
        isForSummaryTemplates,
        languagesDictionary,
    });
    const getRowHeight = () => 'auto';
    const initialState = {
        pagination: {
            paginationModel: { pageSize: TABLE_PAGE_SIZE_OPTIONS[0] },
        },
    };
    const onPaginationModelChange = (model) =>
        setFilterData((prev) => ({ ...prev, ...model }));
    const onRowSelectionModelChange = (model) => setSelectionModel(model);
    const onSortModelChange = (sortModel) => {
        if (!sortModel[0]) return;
        setFilterData((prev) => ({
            ...prev,
            orderBy: sortModel[0].field.toLocaleLowerCase(),
            sortBy: sortModel[0].sort,
        }));
    };
    const pageSizeOptions = TABLE_PAGE_SIZE_OPTIONS;
    const paginationModel = {
        page: paginationData?.current_page - 1 || 0,
        pageSize: filterData?.pageSize || TABLE_PAGE_SIZE_OPTIONS[0],
    };
    const slotProps = { pagination: { pageSizeOptions } };
    const slots = { pagination: CustomPagination };

    return (
        <>
            <Filter setFilterData={setFilterData} />

            <DataGrid
                autoHeight
                columns={columns}
                disableColumnMenu
                getRowHeight={getRowHeight}
                initialState={initialState}
                loading={loading}
                onPaginationModelChange={onPaginationModelChange}
                onRowSelectionModelChange={onRowSelectionModelChange}
                onSortModelChange={onSortModelChange}
                pageSizeOptions={pageSizeOptions}
                pagination
                paginationMode="server"
                paginationModel={paginationModel}
                rowCount={rowCount}
                rows={items}
                selectionModel={selectionModel}
                slotProps={slotProps}
                slots={slots}
                sortingMode="server"
            />
        </>
    );
};

const mapStateToProps = createStructuredSelector({
    branchOfficesDictionary: multiBranchesSelectors.getBranchesData,
    companyUsersData: selectors.getCompanyUsersData,
    loading: selectors.getIsFetchingCustomBaseTemplates,
    items: selectors.getCustomBaseTemplatesData,
    paginationData: selectors.getCustomBaseTemplatesPagination,
});

const mapDispatchToProps = (dispatch) => ({
    filterCustomBaseTemplates: (filterData) =>
        dispatch(actions.filterCustomBaseTemplates(filterData)),
});

CustomTemplatesTab.propTypes = {
    branchOfficesDictionary: PropTypes.array,
    companyUsersData: PropTypes.array,
    control: PropTypes.object,
    filterCustomBaseTemplates: PropTypes.func,
    handleSelectTemplate: PropTypes.func,
    isForSummaryTemplates: PropTypes.bool,
    items: PropTypes.array,
    languagesDictionary: PropTypes.object,
    loading: PropTypes.bool,
    paginationData: PropTypes.object,
};

export default connect(mapStateToProps, mapDispatchToProps)(CustomTemplatesTab);
