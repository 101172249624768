import React, { useEffect, useState } from 'react';

import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import {
    Alert,
    BottomActions,
    Box,
    Button,
    Card,
    DataGrid,
    Typography,
} from 'sunwise-ui';

import { CustomPagination } from 'common/components';
import Search from 'common/components/tables/Search';
import { TABLE_PAGE_SIZE_OPTIONS } from 'common/constants';

import { getFilteredProjects } from '../helpers';

import ProjectColumns from './ProjectColumns';

const ProjectsSection = ({
    contactId,
    disabled,
    fetchProjects,
    isFetching,
    onCancelSelection,
    onSave,
    projects,
    selectedProject,
    setSelectedProject,
}) => {
    const [filteredProjects, setFilteredProjects] = useState(projects || []);
    const { t } = useTranslation();

    useEffect(() => {
        fetchProjects(contactId);
    }, [contactId]);

    useEffect(() => {
        setFilteredProjects(projects);
    }, [projects]);

    const filterProjects = (value) => {
        setFilteredProjects(getFilteredProjects(projects, value));
    };

    const columns = ProjectColumns();
    const initialState = {
        pagination: {
            paginationModel: { pageSize: TABLE_PAGE_SIZE_OPTIONS[0] },
        },
    };
    const pageSizeOptions = TABLE_PAGE_SIZE_OPTIONS;
    const onRowClick = (params) => setSelectedProject(params?.row);
    const selectionModel = [selectedProject?.id];
    const slotProps = { pagination: { pageSizeOptions } };
    const slots = { pagination: CustomPagination };
    const sx = {
        '& .MuiDataGrid-row': { cursor: 'pointer' },
        '& .MuiDataGrid-cell:focus': { outline: 'none' },
    };

    return (
        <>
            <Typography variant="h6" sx={{ mt: 2, ml: 1 }}>
                {t('Select a project')}
            </Typography>

            <Box py={2}>
                <Search
                    handleOnSearchChange={filterProjects}
                    placeholderSearch={t('Search project')}
                />
            </Box>

            <Alert severity="info" sx={{ mt: 2, ml: 1 }}>
                {t(
                    'Only projects that have initiated an after-sales project are shown',
                )}
            </Alert>

            <Card>
                <Card.Body>
                    <DataGrid
                        autoHeight
                        columns={columns}
                        disableColumnMenu
                        hideFooterSelectedRowCount
                        initialState={initialState}
                        loading={isFetching}
                        onRowClick={onRowClick}
                        pagination
                        paginationMode="client"
                        rows={filteredProjects}
                        selectionModel={selectionModel}
                        slotProps={slotProps}
                        slots={slots}
                        sx={sx}
                    />
                </Card.Body>
            </Card>

            <BottomActions>
                <Box display="flex" gap={1} mt={2}>
                    <Button
                        color="secondary"
                        disabled={disabled}
                        onClick={onCancelSelection}
                        variant="outlined"
                    >
                        {t('Back')}
                    </Button>

                    <Button
                        disabled={disabled || !selectedProject}
                        onClick={onSave}
                    >
                        {t('Save')}
                    </Button>
                </Box>
            </BottomActions>
        </>
    );
};

ProjectsSection.propTypes = {
    contactId: PropTypes.string,
    disabled: PropTypes.bool,
    fetchProjects: PropTypes.func,
    isFetching: PropTypes.bool,
    onCancelSelection: PropTypes.func,
    onSave: PropTypes.func,
    projects: PropTypes.array,
    selectedProject: PropTypes.object,
    setSelectedProject: PropTypes.func,
};

export default ProjectsSection;
