import React from 'react';

import PropTypes from 'prop-types';
import { DataGrid } from 'sunwise-ui';

import { CustomPagination } from 'common/components';

const getRowStyles = (selectable) => {
    const styles = { '& .MuiDataGrid-cell:focus': { outline: 'none' } };
    if (selectable) styles['& .MuiDataGrid-row'] = { cursor: 'pointer' };
    return styles;
};

const Table = ({
    columns,
    isLoading,
    items,
    onPageChange,
    onPageSizeChange,
    onRowClick,
    page,
    pageSize,
    paginationMode = 'client',
    rowCount,
    rowsPerPageOptions = [15, 25, 50, 75, 100],
    selectable = true,
    selectionModel,
}) => {
    const getRowHeight = () => 'auto';
    const initialState = {
        pagination: {
            paginationModel: { pageSize: pageSize || rowsPerPageOptions[0] },
        },
    };
    const onPaginationModelChange = (model) => {
        if (model.pageSize !== pageSize && onPageSizeChange)
            onPageSizeChange(model.pageSize);
        if (model.page !== page && onPageChange) onPageChange(model.page);
    };

    const rows = items || [];
    const slotProps = { pagination: { pageSizeOptions: rowsPerPageOptions } };
    const slots = { pagination: CustomPagination };
    const sx = getRowStyles(selectable);

    return (
        <DataGrid
            autoHeight
            columns={columns}
            disableColumnMenu
            disableRowSelectionOnClick
            getRowHeight={getRowHeight}
            hideFooterSelectedRowCount
            initialState={initialState}
            loading={isLoading}
            onPaginationModelChange={onPaginationModelChange}
            onRowClick={onRowClick}
            pagination
            paginationMode={paginationMode}
            rowCount={rowCount}
            rows={rows}
            selectionModel={selectionModel}
            slotProps={slotProps}
            slots={slots}
            sx={sx}
        />
    );
};

Table.propTypes = {
    columns: PropTypes.array,
    isLoading: PropTypes.bool,
    items: PropTypes.array,
    onPageChange: PropTypes.func,
    onPageSizeChange: PropTypes.func,
    onRowClick: PropTypes.func,
    page: PropTypes.number,
    pageSize: PropTypes.number,
    paginationMode: PropTypes.string,
    rowCount: PropTypes.number,
    rowsPerPageOptions: PropTypes.array,
    selectable: PropTypes.bool,
    selectionModel: PropTypes.array,
};

export default Table;
